import { useState } from "react";
import { REQ_NETLOGS } from "../managers/Netlog";
import { Box } from "../components/box/Box";
import { Panel } from "../components/Panel";
import { Config } from "../Config";
import { useTheme } from "styled-components";
import { Spacer } from "../components/Spacer";
import { Button } from "../components/Button";
import { Typo } from "../components/text/Typo";
import dayjs from "dayjs";

let updated=new Date();
export const NetlogPanel = () =>{

    const [logs,retakeLogs,resetLogs,busy]=REQ_NETLOGS.useRequest(()=>{updated=new Date()})
    
    const theme = useTheme();
    return  <Box sx={{
        display:"flex",
        flexDirection:"column",
        flexWrap:"wrap",
        height:"100%"
    }}>
    <Panel busy={busy}  sx={{
        flexGrow:1,
        margin:theme.value(5),
    }} label={"NETLOG/"+Config.scheme.name.toLocaleUpperCase()}>
      

      <Box sx={{
                        flexGrow:"1",
                        overflow:"auto",
                        position:"relative",
                        
                    }}>
                        <Box sx={{position:"absolute",width:"100%"}}>
       
                             {logs && logs.map(val=>{
                                return <pre key={val.id}>{JSON.stringify(val,null,'\t')}</pre>
                            })}
                        </Box>
                    </Box>
                    <Spacer size={theme.value(5)}/>

                    <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:theme.value(5)}}>
                        <Typo variant="small">UPDATED AT:</Typo> <Typo color="white" variant="small">{dayjs(updated).format("HH:mm:ss")}</Typo>
                        <Spacer grow/>
                        <Button disabled={busy} size="small" onClick={()=>retakeLogs(+new Date())}>{busy?"LOADING...":"REFRESH"}</Button> 
                    </Box>
    </Panel>
    </Box>

}


//<div onClick={()=>{if(busy)return;setBusy(true);retakeLogs()}}>REFRESH</div>