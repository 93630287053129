import { TextField, Typography } from "@mui/material"
import { REQ_TERMINALS } from "../managers/Terminals"
import { useEffect, useState } from "react";

export const TerminalsPanel=()=>{
    const [busy,setBusy]=useState<boolean>(false);
    const [terminals,retakeTerminals,resetTerminals]=REQ_TERMINALS.useRequest(()=>setBusy(false))
    
    return <div>
        {terminals && typeof terminals === "object" && <pre>{JSON.stringify(terminals,null,"\t")}</pre>}
    </div>
}