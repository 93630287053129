import { useTheme } from "styled-components";
import { AbsolutePanel } from "../components/AbsolutePanel"
import { Box } from "../components/box/Box";
import { ISession, REQ_SESSION, REQ_SESSION_ADDITIONAL_DATA, S_SESSION_LOADED, S_SESSION_UPDATED } from "../managers/Sessions"
import { useEffect, useState } from "react";
import { Spacer } from "../components/Spacer";
import { Button } from "../components/Button";
import { Typo } from "../components/text/Typo";
import { StatBlock } from "./StatPanel";
import { SessionAdditionalData } from "./SessionAdditionalData";
import { S_YAPILY_FORCE_FINALIZE_DIALOG_REQUEST } from "./YapilyForceFinalizeSessionPanel";
import { SignalHandler } from "badmfck-signal";
import { TabMenu } from "../components/TabMenu";

export const TransactionPanel = () => {
    const theme = useTheme()
    const session = S_SESSION_LOADED.use([]);
    const [menu,setMenu] = useState<"details"|"raw"|"additional">("details")

    useEffect(()=>{
        const sh = new SignalHandler()
        sh.add(S_SESSION_UPDATED,(s:ISession)=>{
            if(s.ssid===session?.ssid)
                S_SESSION_LOADED.invoke({...s})
        })
        return ()=>sh.clear()
    },[session])

   if(!session)
        return null;

   const openTerminal=()=>{
        window.open(`https://openbanking.paysolo.net/session/${session.ssid}`,"_blank")
   }
   
   const changeSessionStatus = () =>{
        S_YAPILY_FORCE_FINALIZE_DIALOG_REQUEST.invoke(session);
   }
   
    let content=null;
    switch(menu){
        case "raw":
            content=<Box sx={{
                backgroundColor:"rgba(0,0,0,.3)",
                fontFamily:"monospace",
                whiteSpace:"pre-wrap",
                color:"greenyellow",
                fontSize:".9em",
                padding:theme.value(5),
            }}>
                {JSON.stringify(session,null,"\t")}
            </Box>
        break
        case "details":
            content=<Box vflex>


                

                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Status</Typo>
                <Spacer size="10px" />
                <StatusInfo session={session} />

                <Spacer size="40px" />
                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>User actions:</Typo>
                <Spacer size="10px" />
                <Log session={session} />
                


                <Spacer size="40px" />
                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Common info</Typo>
                <Spacer size="10px" />
                <CommonInfo session={session} />
                <Spacer size="40px" />
                <Typo block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Client info</Typo>
                <Spacer size="10px" />
                <ClientInfo session={session} />
                <Spacer size="40px" />
                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                    }
                }}>Payment info</Typo>
                <Spacer size="10px" />
                <PaymentInfo session={session} />
                <Spacer size="10px" />
            </Box>
        break;
        case "additional":
            content=<SessionAdditionalData session={session}/>
        break;
    }

    return <AbsolutePanel title="Transaction" sx={{
        width:"50vw",
        height:"100%",
        maxWidth:"650px"
    
    }}>
        <Box vflex sx={{
            height:"100%",
            gap:"10px"
            ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                fontSize:"0.8em"
            }
        }}>
            {/*<Box sx={{
                display:"flex",
                gap:theme.value(5)
                ,"&>div":{
                    textTransform:"uppercase",
                    padding:theme.value(2),
                    borderBottom:"2px solid rgba(0,0,0,.2)",
                    cursor:"pointer"
                    ,"&[data-selected='true']":{
                        color:"#33a0ff",
                        borderBottom:"2px solid #33a0ff",
                        pointerEvents:"none",
                    }
                }
            }}>
                <div onClick={()=>setMenu("details")} data-selected={menu==="details"}>Details</div>
                <div onClick={()=>setMenu("raw")}  data-selected={menu==="raw"}>RAW</div>
                <div onClick={()=>setMenu("additional")}  data-selected={menu==="additional"}>ADDITIONAL</div>
            </Box>*/}

            <TabMenu menu={[
                {id:"details",label:"DETAILS"},
                {id:"raw",label:"RAW"},
                {id:"additional",label:"ADDITIONAL"}
                ]}
                selected={menu}
                onChange={val=>setMenu(val as any)}
             />

            <Box sx={{
                flexGrow:1,
                position:"relative",
                overflow:"auto",
                "&>div":{
                    position:"absolute",
                    width:"100%"
                }
            }}><div>
                {content}
            </div></Box>
            
            <Spacer size="20px"/>
            <Box sx={{display:"flex",gap:"20px"}}>
                <Spacer grow />
                {(session.isOpenBanking) /*&& session.status!=="completed"*/ && <Button size="small" variant="danger" onClick={changeSessionStatus}>FINALIZE SESSION</Button>}
                {(session.isOpenBanking)  && <Button size="small" sx={{backgroundColor:"red"}} onClick={openTerminal}>OPEN TERMINAL</Button>}
                <Button size="small" onClick={()=>{S_SESSION_LOADED.invoke(null)}}>CLOSE</Button>
            </Box>

        </Box>
    </AbsolutePanel>
}


const CommonInfo=({session}:{session:ISession})=>{
    const theme = useTheme()
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat( auto-fit, minmax(250px,1fr) )",
        gap:theme.value(3)
    }}>

    <StatBlock name={"Created"} value={session.ctime} small/>
    <StatBlock name={"Transaction ID"} value={session.transaction_id} small/>
    <StatBlock name={"Branch"} value={session.branch} small/>
    <StatBlock name={"Terminal"} value={session.terminal} small/>
    <StatBlock name={"Payment system"} value={session.paymentSystem ?? session.payment_system} small/>
    {session.merchant_id && <StatBlock name={"Merchant ID"} value={session.merchant_id} small/>}

    </Box>
}

//session.status=="fail"?"#e91e63":
const StatusInfo=({session}:{session:ISession})=>{
    const theme = useTheme()
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"1fr",
        gap:theme.value(3)
    }}>
        <Box sx={{
            display:"grid",
            gridTemplateColumns:"1fr 1fr",
            gap:theme.value(3)}}
        >
            <StatBlock bold name={"Status"} danger={session.status==="fail"} value={session.status && session.status.toUpperCase()} small/>
            <StatBlock bold name={"Transaction ID"} value={session.transaction_id} small/>
        </Box>
        {session.fail_reason && <StatBlock name={"Fail reason"} value={session.fail_reason} noborder small/>}
    </Box>
}

const ClientInfo=({session}:{session:ISession})=>{
    const theme = useTheme()
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat( auto-fit, minmax(250px,1fr) )",
        gap:theme.value(3)
    }}>
        <StatBlock name={"Email"} value={session.email} small/>
        <StatBlock name={"Ip"} value={session.ip ?? "??.??.??.??"} small/>
        <StatBlock name={"Country"} value={session.country?.toUpperCase() ?? "??"} noborder small/>
    </Box>
}

const PaymentInfo=({session}:{session:ISession})=>{
    const theme = useTheme()
    let paymethod=null;
    if(session.paymentSystem=="Yapily"){

        const cmask = session.card_mask ?? ""
        let iban =session.card_mask;
        let pspIban="";
        if(cmask && cmask.indexOf(",")!==-1){
            const tmp = cmask.split(",")
            iban=tmp[0]
            pspIban=tmp[1]
        }

        paymethod = <><StatBlock name={"CLIENT IBAN"} value={iban ?? ""} small/>
        <StatBlock name={"PSP IBAN"} value={pspIban ?? ""} small/></>
    }else{
        paymethod = <StatBlock name={"Card"} value={session.card_mask ?? ""} small/>
    }
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat( auto-fit, minmax(250px,1fr) )",
        gap:theme.value(3)
    }}>
        
       
        {paymethod}

        <StatBlock name={"Amount"} bold value={(session.amount/100).toFixed(2)} small/>
        <StatBlock name={"Currency"} bold value={session.currency.toUpperCase()} small/>
        {session.dev_id && <StatBlock name={"Apple Device"} value={session.dev_id} small/>}
        {session.ap_num && <StatBlock name={"Apple Card Num"} value={session.ap_num} small/>}
        {session.gp_hash && <StatBlock name={"GooglePay Card Hash"} value={session.gp_hash} small/>}

        
    </Box>
}



       /*       "email": "micko73@icloud.com",
	"ap_num": "5349435385164436-270331",
	"gp_hash": null,
	"dev_id": "050110030273",
	"merchant_id": null*/

const Log = ({session}:{session:ISession}) =>{
    const theme = useTheme()
    return <Box vflex sx={{
        gap:theme.value(1),
        
    }}> {session && session.log && session.log.map((val,i)=>{
        return <Box sx={{
            backgroundColor:"rgba(0,0,0,.1)",
            display:"flex",
            padding:`${theme.value(3)} ${theme.value(5)}`,
            gap:theme.value(3),
            alignItems:"center",
        }} key={i}>
            <Typo variant="small">
                {(i+1).toString().padStart(2,"0")}
            </Typo> 
            <Typo sx={{
                "&:first-letter":{
                    textTransform:"capitalize"
                }
            }}>
                {getProperLogValue(val)}
            </Typo>
        </Box>
    })}</Box>
}


const getProperLogValue=(log:string):string=>{

    switch (log){
        case "created":
        return "Session was created"
        case "redirect":
        return "User redirected to form"
        case "checked":
        return "User open payment form"
        case "ap has card":
        return "Found Apple Pay Card"
        case "btnclick":
        return "User press payment button"
        case "ap, pay session created":
        return "Apple Pay session created"
        case "process with ap":
        return "Request payment details from Apple Pay"
        case "cnet: MasterCard":
        return "Detected card network: MasterCard"
        case "ap request pay":
        return "Transfer payment request to acquirer"
        case "redirect, manual: 0":
        return "User returns to merchant by timer"
        case "redirect, manual: 1":
        return "User returns to merchant using button"
        case "trx ok, s: 1":
        return "Transaction was successfull"
        case "ap not available":
        return "Apple Pay not available"
        case "winclose":
        return "User close window with form"
        case "cnet: MASTERCARD":
        return "Detected card network: MasterCard"
        case "gp decrypt token":
        return "Google card token successfully decrypted"
        case 'gp request pay':
        return "Transfer payment request to acquirer"
        
        
    }

    return log
}