import { useTheme } from "styled-components"
import { Box } from "./box/Box"

export const Burger = ({onClick,open}:{onClick:()=>void,open:boolean}) =>{
    const theme = useTheme();
    const burgerColor = "#e1fbff"
    const gap=3;
    const height=2;
    const boxHeight = 20;
    const barStartY=Math.round((20-(height*3+gap*2))/2)
    return <Box onClick={onClick} sx={{ 
            boxShadow:"0px 2px 2px rgba(0,0,0,.3)",
            display: "flex",
            cursor:"pointer",
            flexDirection:"column",
            gap:"4px",
            
            position:"relative",
            width:"20px",
            height:boxHeight+"px"
         }}>
        <Box sx={{
            borderRadius:"2px",
            width: "100%",
            height: height+"px",
            backgroundColor: burgerColor,
            position:'absolute',
            transform: open?"rotate(45deg)":"rotate(0deg)",
            transition:"transform .3s, top .3s",
            top:open?barStartY+height+gap+"px":barStartY+"px"
        }}/>
        {!open && <Box sx={{
            borderRadius:"2px",
            width: "100%",
            height: height+"px",
            position:'absolute',
            backgroundColor: burgerColor,
            top:barStartY+height+gap+"px",
        }}/>}
        <Box sx={{
            borderRadius:"2px",
            width: "100%",
            height: height+"px",
            backgroundColor: burgerColor,
            position:'absolute',
            transform: open?"rotate(-45deg)":"rotate(0deg)",
            transition:"transform .3s",
            top:open?barStartY+height+gap+"px":barStartY+height*2+gap*2+"px"
        }}/>
    </Box>
}