import { useEffect, useState } from "react";
import { ISession, REQ_SESSION_ADDITIONAL_DATA } from "../managers/Sessions";
import { Box } from "../components/box/Box";
import { StatBlock } from "./StatPanel";
import { Typo } from "../components/text/Typo";
import { useTheme } from "styled-components";
import dayjs from "dayjs";
import { Spacer } from "../components/Spacer";

export const SessionAdditionalData = ({session}:{session:ISession}) => {
  
    const [additionalData] = REQ_SESSION_ADDITIONAL_DATA.useRequest(null,session);
    
    if(!additionalData)
        return null;

    if(typeof additionalData==="object" && "code" in additionalData && "message" in additionalData)
        return <Box sx={{whiteSpace:"pre",overflowX:"auto",color:"greenyellow", fontFamily:"monospace",padding:"10px",backgroundColor:"rgba(0,0,0,.3)"}}>
                    {JSON.stringify(additionalData,null,"\t")}
                </Box>

    additionalData.sort((a:any,b:any)=>{
        return a.desc_sort>b.desc_sort?-1:1
    })

    return <Box vflex sx={{gap:"20px"}}>
        {additionalData.map((val:any,index:number)=>{
            return <AdditionalDataBlock key={index} value={val}/>
        })}
    </Box>
}

const AdditionalDataBlock = ({value}:{value:any}) => {
    const [state,setState]=useState<boolean>(true);
    const theme = useTheme()
    return <Box>
            
            <StatBlock name={"Created"} value={dayjs(value.created_at).format("DD, MMM, YYYY - HH:mm")} small/>

            <Box onClick={()=>{
                setState(!state)
            }}  sx={{
                cursor:"pointer",
                borderRadius:"6px 6px 0 0",
                backgroundColor:"rgba(0,0,0,.5)",
                padding:theme.value(5),
                display:"flex"
                ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    padding:theme.value(3),
                    
                }
            }}>
                <Typo block>{value.data.name}</Typo>
                <Spacer grow/>
                <Typo variant="caption" block>{value.type.toUpperCase()}</Typo>
            </Box>
            
            
            <Box sx={{whiteSpace:"pre",overflowX:"auto",color:"greenyellow", fontFamily:"monospace",padding:"10px",backgroundColor:"rgba(0,0,0,.3)"}}>
                {value.type === "initialrequest" && state && JSON.stringify(value.data.data,null,"   ")}
                {value.type !== "initialrequest" && state && JSON.stringify(value.data,null,"   ")}
            </Box>
        </Box>
}