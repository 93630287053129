import Signal, { Req } from "badmfck-signal";
import { IError, REQ_API } from "./Api";
import dayjs from "dayjs";

export interface ISearchSessionFilter{
    search:string
}

export interface IFilter{
    search?:string,
    ob?:boolean,
    ap?:boolean,
    gp?:boolean
    re?:boolean,
    pr?:boolean,
    mid?:number,
    ignoreEmptyRequests?:boolean,
    date?:number // YYYY-MM-DD
    ts:number

}

export interface ISession{

    amount:number,
    card_mask:string,
    ctime:string,
    timestamp:number,
    currency:string,
    fail_reason:string,
    ip:string,
    log:string[],
    payment_system:"ap"|"gp"|"apgp"|"yapily"|"yapily_instant",
    paymentSystem?:string,
    req: any,
    ssid:string,
    status:string,
    terminal: string,
    transaction_id:string,
    trx:any,
    country:string,
    branch:string,
    email:string
    merchant_id:number

    gate_status:string

    ap_num:string,
    gp_hash:string,
    dev_id:string

    institution?:string

    isOpenBanking?:boolean
}

export const S_SESSION_UPDATED = new Signal<ISession>()

export const REQ_SESSION_UPDATE = new Req<ISession,ISession>(async req=>{
    prepareSession(req);
    S_SESSION_UPDATED.invoke(req)
    return req;
})


export const S_LOAD_SESSION_ADDITIONAL_DATA=new Signal<boolean>();

export const S_SESSION_LOADED = new Signal<ISession|null>()
export const REQ_SESSION_ADDITIONAL_DATA = new Req<ISession,any|IError>(async req=>{
    S_LOAD_SESSION_ADDITIONAL_DATA.invoke(true)
    const resp = await REQ_API.request({
        data:{ssid:req.ssid},
        endpoint:"session/additional"
    })
    S_LOAD_SESSION_ADDITIONAL_DATA.invoke(false)
    if(resp.error){
        return resp.error
    }

    


    return resp.data
})

export const REQ_SESSION=new Req<ISearchSessionFilter,ISession[]|IError|null>(async req=>{
    const resp = (await REQ_API.request({
        data:req,
        endpoint:"session/search",
    }));

    if(resp.error)
        return resp.error;

    if(!resp.data){
        return {code:2,message:"No session found"}
    }

    for(let i of resp.data)
        prepareSession(i)

    return resp.data
});



// session requestor
export const REQ_SESSIONS=new Req<IFilter,ISession[]>(async (filter)=>{

   

    const resp = await REQ_API.request({
        endpoint:"sessions",
        data:{
            filter:filter
        },
    })
    

    if(resp.error || !resp.data){
        console.log(resp.error)
        return []
    }
        
    if(resp.data.sessions){
        for(let i of resp.data.sessions){
            prepareSession(i)
        }
        return resp.data.sessions
    }
    return []
});


const psys:Record<string,string>={
    "ap":"Apple Pay",
    "gp":"Google Pay",
    "apgp":"Apple Pay/Google Pay",
    "yapily":"Yapily",
    "yapily_instant":"Yapily Instant",
    "yapily_repay":"Yapily Repay"
}

const prepareSession=(session:ISession)=>{
    session.timestamp = +new Date(session.ctime)
    session.ctime=dayjs(new Date(session.timestamp)).format("YYYY, MMM DD - HH:mm:ss")
    session.paymentSystem = psys[session.payment_system] ?? session.payment_system
    session.paymentSystem = session.paymentSystem.toLowerCase()

    if(session.paymentSystem === "yapilly")
        session.paymentSystem = "yapily"

    if(session.paymentSystem === "yapilly_instant")
        session.paymentSystem = "yapily_instant"

    if(session.paymentSystem === "yapily" || session.paymentSystem === "yapily_instant")
        session.isOpenBanking=true;

    if(session.terminal === "em_obop_yapilly"){
        session.paymentSystem = "Yapily Repay"
        session.isOpenBanking=true;
    }
        

    try{
        if(typeof session.log === "string")
            session.log = JSON.parse(session.log)
    }catch(e){}

    if(typeof session.req === "string"){
        try{
            session.req=JSON.parse(session.req as string)
        }catch(e){}

    }
    
    if(typeof session.trx === "string"){
        try{
            session.trx=JSON.parse(session.trx as string)
        }catch(e){}
    }

    if(session.log && session.log.length === 1 && (session.ip === null || session.ip==="null"))
        session.status="problem"

    if(!session.status)
        session.status="unknown"


    if(session.isOpenBanking){
        if(session.status === "completed")
            session.status="processed"
        
        if(session.gate_status)
            session.status=session.gate_status==="success"?"completed":session.gate_status
    }

    if(session.status ==="created" && session.timestamp < Date.now()-1000*60*10)
        session.status="abandoned"

}

