import { Req } from "badmfck-signal"
import { AbsolutePanel } from "../components/AbsolutePanel"
import { Box } from "../components/box/Box"
import { TextInput } from "../components/input/TextInput"
import React, { FormEvent } from "react"
import { Button } from "../components/Button"
import { useTheme } from "styled-components"
import { Spacer } from "../components/Spacer"

export type FormFieldType="text"|"number"|"password"|"tel"|"login"|"select"|"checkbox"

export interface FromField{
    element?:"text"
    type?:FormFieldType,
    label?:string,
    name:string
    values?:any[]|null
    value?:any,
    titleFieldName?:string
}

export interface FormFieldGroup{
    sx?:React.CSSProperties,
    label?:string
    fields:FromField[]
}

export interface FormConstructorProps{
    title:string,

    // requst to work with form
    formRequest?:Req<void,void>

    // data object
    form:any

    error?:{code:number,message:string}|null

    // Form Fields
    fieldsGroups:FormFieldGroup[]

    sx?:React.CSSProperties

    onSubmit?:(form:any)=>void

    onCancelClick?:()=>void
    onAdditionalClick?:()=>void

    btnCancelTitle?:string
    btnSubmitTitle?:string
    btnAdditionalTitle?:string
}

export const FormConstructor = (params:FormConstructorProps) => {
    const theme = useTheme();

    const onFormSubmit=(e:FormEvent)=>{
        e.preventDefault();
        e.stopPropagation();
        if(params.onSubmit)
            params.onSubmit(params.form)
    }

    return <AbsolutePanel title={params.title} sx={params.sx}>
        <form onSubmit={onFormSubmit}>  
        <Box sx={{display:"flex",flexDirection:"column",gap:"12px",height:"100%"}}>

            {params.error && <Box sx={{
                border:"1px solid #ff0084",
                textAlign:"center",
                fontWeight:"500",
                fontSize:"0.85em",
                padding:theme.values.value16px,
                borderRadius:theme.values.borderRadius,
                backgroundColor:"rgba(0,0,0,.1)"
            }}>
                {params.error.message}
            </Box>}
            
                {params.fieldsGroups.map((val,i)=>{

                    if(val.fields.length===0 && !val.label)
                        return null;

                    return <Box key={i} sx={{
                        borderRadius:val.label?"4px":undefined,
                        display:"flex"
                        ,flexDirection:"column"
                        ,gap:theme.value(4)
                    }}>
                        {val.label && <Box sx={{fontSize:theme.value(4)}}>{val.label}</Box>}

                        <Box sx={{
                            display:val.fields.length>1?"grid":"block",
                            gap:theme.value(6),
                            ...val.sx
                        }}>
                            {val.fields.map((field,index)=>{
                                let element=null;
                                
                                if(!field.element || field.element === "text")
                                    element = <TextInput key={index} label={field.label} name={field.name} form={params.form} type={field.type} values={field.values} titleFieldName={field.titleFieldName} readonly={field.type==="select"}/>
                                
                                return element

                            })}
                        </Box>

                    </Box>
                })}
            
            <Spacer size={theme.value(10)}/>
            <Box sx={{
                display:"flex",
                flexDirection:"row",
                gap:theme.value(4),
            }}>

                {params.onAdditionalClick && <Button variant="outline" onClick={()=>{if(params.onAdditionalClick)params.onAdditionalClick()}}>{params.btnAdditionalTitle ?? "DELETE"}</Button>}
                <Spacer grow/>
                {params.onCancelClick && <Button variant="outline" onClick={()=>{if(params.onCancelClick)params.onCancelClick()}}>{params.btnCancelTitle ?? "CANCEL"}</Button>}
                <Button element="input" value={params.btnSubmitTitle ?? "SEND"}/>
            </Box>
        </Box>
        </form>
    </AbsolutePanel>
}