
import { useState } from "react";
import { Button } from "../components/Button"
import { Box } from "../components/box/Box";
import { TextInput } from "../components/input/TextInput";
import { REQ_YAPILY_CREATE_SUBAPP, REQ_YAPILY_GET_BANK_GROUPS, REQ_YAPILY_GET_BANK_LIST } from "../managers/Yapilly"
import { Pre } from "../components/Pre";
import { Typo } from "../components/text/Typo";
import Signal from "badmfck-signal";
import { Panel } from "../components/Panel";
import { useTheme } from "styled-components";
import { Spacer } from "../components/Spacer";



export const S_YAPILY_BANK = new Signal<IYapilyInstitution|null>()

export const YapilyPanel = () =>{
    const theme = useTheme()
    const [search,setSearch] = useState<string|null>(null)
    const [data] = REQ_YAPILY_GET_BANK_LIST.useRequest()
   
    if(!data)
        return null;

        /*"uuid": "3292af39-da54-44a3-9d8b-f39df607295a",
	"name": "Impaya Prod",
	"active": true,
	"authCallbacks": [],*/

    const onSearchClick = (search:string)=>{
        setSearch(search)
    }

    const onResetClick = ()=>{
        setSearch(null)
    }



    return  <Box sx={{
        padding:theme.value(5),
        display:"grid",
        gridTemplateColumns:"minmax(800px,1fr) 0.4fr",
        flexWrap:"wrap",
        height:"100%"
        ,gap:theme.value(5)
        ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
            gridTemplateColumns:"1fr",
        }
    }}>
        
         <Panel label="YAPILY Management">

<Spacer size={theme.value(3)}/>
                    
                    
                    
        
        
        <TextInput type="search" label="Search" onSearch={search=>{
            onSearchClick(search)
        }} onReset = {onResetClick}/>

        <BankGroups/>
<Spacer size={theme.value(8)}/>

                    <Box sx={{
                        flexGrow:"1",
                        overflow:"auto",
                        position:"relative",
                        minHeight:"60vh"
                    }}>
                        <Box sx={{position:"absolute",width:"100%"}}>

                            <Box sx={{
                                gap:"20px",
                                display:"grid",
                                gridTemplateColumns:"repeat(auto-fill,minmax(200px,1fr))",
                            }}>
                            {data && data.institutions && data.institutions.sort((a:IYapilyInstitution,b:IYapilyInstitution)=>{
                                if(a.name>b.name)
                                    return 1
                                if(a.name<b.name)
                                    return -1
                                return 0
                            }).map((val:IYapilyInstitution,index:number)=>{

                                if(search){
                                    if(!val.fullName.toLowerCase().includes(search.toLowerCase()))
                                        return null;
                                }

                                return <YapilyBank key={index} bank={val}/>
                            })}        

                            </Box>

                        </Box>
                    </Box>
        </Panel></Box>
}

/*
<Button onClick={ async ()=>{

        //const resp = await REQ_YAPILY_CREATE_SUBAPP.request();
       // console.log(resp)
        alert("Method commented!, TODO: constructor")


    }}>CREATE SUBAPP </Button>*/
const YapilyBank = ({bank}:{bank:IYapilyInstitution}) => {

    const theme = useTheme()
    
    const icon = bank.media?.find((val:IYapilyMedia)=>val.type==="icon")?.source

    return <Box vflex sx={{
            border:"1px solid rgba(255,255,255,.1)",
            padding:theme.value(3),
            alignItems:"center",
            justifyContent:"center",
            gap:"10px",
            cursor:"pointer",
            borderRadius:theme.value(2),
            backgroundColor:"rgba(0,0,0,.1)",
            transition:"background-color .3s",
            "&:hover":{
                backgroundColor:"rgba(0,0,0,.3)"
            }
        }}
        onClick={()=>{
            S_YAPILY_BANK.invoke(bank)
        }}
    >
        <Box sx={{
            height:"60px",
            width:"60px",
            backgroundColor:"rgba(255,255,255,.1)",
            backgroundPosition:"center",
            backgroundSize:"contain",
            backgroundRepeat:"no-repeat",
            borderRadius:"50%"
        }}
            style={{
            backgroundImage:`url(${icon})`,
        }}/>
       <Typo variant="caption" align="center">{bank.fullName}</Typo>
    </Box>

}


const BankGroups=()=>{
    const [groups] = REQ_YAPILY_GET_BANK_GROUPS.useRequest();
    let content = null;
    if(groups && Array.isArray(groups)){
        content = groups.map((val:IYapilyBankGroup,i:number)=>{
            return <Box key={i} vflex sx={{
                gap:"10px",
                padding:"10px",
                backgroundColor:"rgba(0,0,0,.1)",
                borderRadius:"4px"
            }}
                onClick={()=>{
                    console.log("GROUP CLICKED",val)
                }}
            >
                <Typo block>{val.full_name}</Typo>
                <Pre value={val} shown/>
            </Box>
        })
    }
    return <Box>{content}</Box>
}