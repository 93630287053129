import { Req } from "badmfck-signal";
import { REQ_API } from "./Api";
export interface ILogItem {
    id: number;
    level: string;
    time: number;
    text: string;
    date: string;
    source: string;
}
export const REQ_LOGS = new Req<any,ILogItem[]>();
export class Logs{
    constructor(){
        REQ_LOGS.listener = async ()=>await this.getLogs()
    }

    async getLogs(){
        try{
            const resp  = await REQ_API.request({
                endpoint:"log",
                data:{},
            })
            if(resp.error)
                return resp.error

            const logs:ILogItem[]=resp.data;
            
            logs.sort((a,b)=>b.time-a.time)

            return resp.data
        }catch(e){
            return {code:-2,message:"Can't load terminals"}    
        }
    }
}