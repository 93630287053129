import { ChangeEvent, useState } from "react"
import { styled, useTheme } from "styled-components"
import { Box } from "../box/Box"
import { Button } from "../Button"
import { Typo } from "../text/Typo"
import { ArrowLeft } from "../../assets/ArrowLeft"
import { ArrowRight } from "../../assets/ArrowRight"


interface ITextInput{
    label?:string
    placeholder?:string
    mask?:RegExp
    value?:string|null
    values?:{[key:string]:any}|null,
    titleFieldName?:string,
    onChange?:(value:string)=>void
    name?:string
    form?:any,
    type?:string
    disabled?:boolean
    readonly?:boolean
    sx?:React.CSSProperties
    onSearch?:(search:string)=>void
    onReset?:()=>void
}

const InputEl = styled.input`
    flex-grow: 1;
    outline:none;
    border:none;
    padding:0;
    margin: 0;
    font-size: 14px;
    color:#FFFFFF;
    background-color: transparent;
    padding:12px 18px;
    width: 100%;
    &::placeholder{
        font-style: italic;
    }
    
`

const InputHolder = styled.div<{$type:string}>`
    border-radius: 4px;
    border:1px solid rgba(255,255,255,.1);
    background-color: rgba(0,0,0,.1);
    display: flex;
    flex-grow: 1;
    ${({$type})=>{
        if($type==="select" || $type ==="data" || $type==="checkbox" || $type==="date" || $type==="month")
            return "cursor:pointer;"
        return "";
    }}
`


const setupDefaultValue=(value?:string|null,name?:string,form?:any,mask?:RegExp,type?:string):string=>{

    let val=value ?? "";
    if(form && typeof form ==="object" && name && typeof name==="string" && name.length){
        val = form[name]
    }
    if(mask && typeof val ==="string")
        val = val.replaceAll(mask,"") 

    if(type==="day" || type==="month"){
        const d = new Date(val);
        if(type==="day")
        val = d.getFullYear()+"-"+(d.getMonth()+1).toString().padStart(2,"0")+"-"+d.getDate().toString().padStart(2,"0")
        else
        val = d.getFullYear()+"-"+(d.getMonth()+1).toString().padStart(2,"0")
    }

    return val;
}

export const TextInput=({value,titleFieldName,label,placeholder,mask,onChange,form,name,type="text",readonly,sx,onSearch,onReset,values}:ITextInput)=>{
    const theme = useTheme()
    const [v,setValue] = useState<any>( setupDefaultValue(value,name,form,mask,type))

    const [dropOpened,setDropOpened]=useState<boolean>(false);
    const onInputChange=(e:ChangeEvent<HTMLInputElement>)=>{
        
        if(readonly)
            return;

        let val =e.currentTarget.value
        if(mask)
            val = val.replaceAll(mask,"") 
        setValue(val ?? "")

        if(form && typeof form ==="object" && name && typeof name==="string" && name.length)
            form[name] = val
        
        if(onChange)
            onChange(e.currentTarget.value)
    }

    let autocomplete=undefined;
    if(type==="password")
        autocomplete="current-password"
    if(type==="login"){
        autocomplete="username"
        type="text"
    }

    let displayValue = v ?? "";
    if(type==="select"){
        displayValue=(v && titleFieldName && titleFieldName in (v as any) && (v as any)[titleFieldName])?(v as any)[titleFieldName]+"":(v)?JSON.stringify(v):""
    }
    
    let inputType = type
    if(inputType==="day" || inputType==="month" || inputType==="range" || inputType==="select"){
        inputType="text"
        readonly=true;
    }


    if(type==="checkbox"){
        return <Box sx={{
            display:"flex",
            gap:theme.value(3),
            position:"relative",
            alignItems:"center",
            ...sx
        }}>

            <Box sx={{
                display:"flex",
                gap:theme.value(5),
              
            }}>
                <InputHolder $type={type}
                        onClick={()=>{
                            const newValue:boolean = v?false:true;
                            console.log(value,newValue)
                            setValue(newValue)
                            if(form && typeof form ==="object" && name && typeof name==="string" && name.length)
                                form[name] = newValue
                            if(onChange)
                                onChange(newValue?"1":"0")
                    }} 
                >
                <Box sx={{
                    
                    boxShadow:v?"inset 0 0 0 1px rgba(255, 255, 255, 0.092)":undefined,
                    backgroundColor:v?theme.colors.buttonBgColor:"rgba(0,0,0,.3)",
                    margin:"8px",
                    width:"12px",
                    height:"12px",
                    borderRadius:"2px",
                    transition:"background-color 0.2s",
                }}/>
                  
                </InputHolder>
            </Box>
            <Typo sx={{
                textTransform:"uppercase",
            }} variant={"small"}>{label}</Typo>
        </Box>
    }

    return <Box sx={{
        display:"flex",
        flexDirection:"column",
        gap:theme.value(1),
        position:"relative",
        ...sx
    }}>
        {label && <Typo sx={{
            
            textTransform:"uppercase",
            
        }} variant={"small"}>{label}</Typo>}

        <Box sx={{
            display:"flex",
            gap:theme.value(5),
            width:"100%",
        }}>

        

        <InputHolder $type={type}>
            <InputEl placeholder={placeholder} value={displayValue} autoComplete={autocomplete} type={inputType} readOnly={readonly} onChange={onInputChange} />
            {type==="select" && <Box sx={{
                backgroundColor:"rgba(0,0,0,.1)",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                width:"35px",
                minWidth:"35px"
            }} onClick={()=>setDropOpened(!dropOpened)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 9V11L12 16L7 11V9L12 14L17 9Z" fill="#FFFFFF" fillOpacity="1"/>
                </svg>
            </Box>}

            {(type==="day" || type==="month"  || type==="range") && <Box sx={{
                backgroundColor:"rgba(0,0,0,.1)",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                width:"35px",
                minWidth:"35px"
            }} onClick={()=>setDropOpened(!dropOpened)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 9V11L12 16L7 11V9L12 14L17 9Z" fill="#FFFFFF" fillOpacity="1"/>
                </svg>
            </Box>}

        </InputHolder>

        


        {onSearch && <Button onClick={()=>{
            onSearch(v ?? "")
        }}>Search</Button>}

        {onReset && <Button sx={{
             "&[data-variant='filled']":{
                backgroundColor:theme.colors.buttonBgColorSecondary,
            }
        }} onClick={()=>{
            setValue("")
            onReset()
        }}>Reset</Button>}

        </Box>

        {type==="select" && values && dropOpened && 
            <Box sx={{
                position:"absolute",
                width:"100%",
                top:"100%",
                zIndex:100,
                backgroundColor:"#15111aEF",
                borderRadius:"4px",
                maxHeight:"30vh",
                overflow:"auto"
            }}>
                {values.map((val:any,index:number)=>{

                    let title=(titleFieldName && titleFieldName in val)?val[titleFieldName]+"":JSON.stringify(val)

                    return <Box key={index} data-selected={v === val} sx={{
                        padding:`${theme.value(4)}`,
                        cursor:"pointer",
                        borderBottom:"1px solid rgba(255,255,255,.05)",
                        "&:hover":{
                            backgroundColor:"rgba(0,0,0,.3)"
                        }
                        
                        ,"&[data-selected='true']":{
                            backgroundColor:"rgba(0,0,0,.3)",
                            color:"#FFAAEE"
                        }
                        
                    }} onClick={()=>{
                        //setup value!
                        setValue(val)
                        setDropOpened(false)
                        if(form && typeof form ==="object" && name && typeof name==="string" && name.length)
                            form[name] = val
                        if(onChange)
                            onChange(val)
                    }}>{title}</Box>
                })}
            </Box>
        
        }


        {(type==="day" || type==="month" || type==="range")  && dropOpened && 
            <Calendar value={v} type={type?"day":type} onChange={date=>{
                setValue(setupDefaultValue(date.toDateString(),name,form,mask,type))
                setDropOpened(false)
                if(onChange)
                    onChange(date.toDateString())
            }}/>
        }

    </Box>
}




    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const getDaysInMonth = (year: number, month: number): number => {
        return new Date(year, month + 1, 0).getDate();
    };


    const Calendar = ({ value,onChange,type }: { value: string,type?:"day"|"month"|"year",onChange:(date:Date)=>void }) => {
        
        if(!type)
            type="day"

        const [date,setDate] = useState<Date>(new Date(value ?? new Date()))
        
        const theme = useTheme();
        const year = date.getFullYear();
        const month = date.getMonth();
        const daysInMonth = getDaysInMonth(year, month);
        const firstDayOfMonth = new Date(year, month, 1).getDay();

        
      

        const handleDateChange = (date:Date)=>{
            if(onChange)
                onChange(date)
        }
        
        const renderMonthes = () => {
            const monthes = [];
            const selectedMonth = date.getMonth();
            for (let i = 0; i < 12; i++) {
                const selected = i === selectedMonth;
                const date=new Date(year,i);
                monthes.push(<Day key={i} selected={selected} day={date.toLocaleString('default', { month: 'short' })} date={date} type="current" onClick={handleDateChange}/>);
            }
            return monthes;
        }

        const renderDays = () => {
            const days = [];
            const prevMonth = new Date(year, month - 1);
            const prevMonthDays = getDaysInMonth(prevMonth.getFullYear(), prevMonth.getMonth());
            let m=0;
            // Render previous month days
            for (let i = firstDayOfMonth - 2; i >= 0; i--) {
               days.push(<Day key={m++} selected={false} day={prevMonthDays - i} date={new Date(year,month - 1,prevMonthDays - i)} type="previous" onClick={handleDateChange}/>);
            }

            // Render current month days
            for (let i = 1; i <= daysInMonth; i++) {
                const selected = date.getDate() === i && date.getFullYear() === year && date.getMonth() === month;                
                days.push(<Day  key={m++}  selected={selected} day={i} date={new Date(year,month,i)} type="current" onClick={handleDateChange}/>);
            }

            // Render next month days
            const totalDays = days.length;
            const remainingCells = 42 - totalDays; // Assuming 6 rows in the calendar
            for (let i = 1; i <= remainingCells; i++) {
                days.push(<Day  key={m++}  selected={false} day={i} date={new Date(year,month+1,i)} type="next" onClick={handleDateChange}/>);
            }

            return days;
        };

        return (
            <Box sx={{
                position:"absolute",
                top:"100%",
                zIndex:100,
                //backgroundColor:"#15111aEF",
                borderRadius:"4px",
                maxHeight:"40vh",
                overflow:"auto",
                minWidth:"100%"
            }}>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    padding:`${theme.value(3)}`,
                    backgroundColor:theme.colors.panelBgColor,
                }}>
                    <Box onClick={()=>{
                        if(type==="day")
                            setDate(new Date(year,month-1))
                        else
                            setDate(new Date(year-1,month))
                    }}>
                        <ArrowLeft/>
                    </Box>
                    <Box>
                        <Typo variant="caption">{date.toLocaleString("default", { month: "long" })} {year}</Typo>
                    </Box>
                    <Box onClick={()=>{
                        if(type==="day")
                            setDate(new Date(year,month+1))
                        else
                            setDate(new Date(year+1,month))
                    }}>
                        <ArrowRight/>
                    </Box>
                </Box>
                {type === "day" &&<Box sx={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}>
                    {daysOfWeek.map((day,index) => (
                        <Box key={index} sx={{
                            backgroundColor: "rgba(0,0,0,1)",
                            padding: `${theme.value(3)}`,
                            textAlign: "center"
                        }}>
                            <Typo variant="small">{day}</Typo>
                        </Box>
                    ))}
                </Box>}
                <Box sx={{ display: "grid", gridTemplateColumns: type==="day"?"repeat(7, 1fr)":"repeat(3, 1fr)" }}>
                    {type==="day" && renderDays()}
                    {type==="month" && renderMonthes()}
                </Box>
                <Box onClick={
                    ()=>{
                        setDate(new Date())
                    }
                } sx={{
                    padding:`${theme.value(3)}`,
                    backgroundColor:theme.colors.panelBgColor,
                    textAlign:"center",
                    cursor:"pointer"
                }}>
                    <Typo variant="caption">Today</Typo>
                </Box>
            </Box>
        );
    };

    const Day = ({day,type,date,selected,onClick}:{day:number|string,date:Date,selected:boolean,onClick:(date:Date)=>void,type:"next"|"current"|"previous"}) =>{
        const theme = useTheme();
        return <Box sx={{
            padding:"8px",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            borderBottom:"1px solid rgba(255,255,255,.1)",
            borderRight:"1px solid rgba(255,255,255,.1)",
            color: (type !== "current") ? "rgba(255,255,255,.4)" : theme.colors.textPrimary,
            cursor:"pointer",
            backgroundColor:selected?theme.colors.buttonBgColor:theme.colors.panelBgDarken,
        }} onClick={()=>onClick(date)}>
            <Typo variant="caption">{day}</Typo>
        </Box>
    }
