import dayjs from "dayjs";
import { useState } from "react";
import { useTheme } from "styled-components";
import { StatBlock } from "../panels/StatPanel";
import { Box } from "./box/Box";
import { Typo } from "./text/Typo";

export const Pre = ({value,label,date,shown}:{value:any,label?:string,date?:any,shown?:boolean}) => {
    const [state,setState]=useState<boolean>(shown ?? false);
    const theme = useTheme()
    return <Box>
            
            {date && <StatBlock name={"Created"} value={dayjs(date).format("DD, MMM, YYYY - HH:mm")} small/>}

            {label && <Typo onClick={()=>{
                setState(!state)
            }}  block sx={{
                cursor:"pointer",
                borderRadius:"6px 6px 0 0",
                backgroundColor:"rgba(0,0,0,.5)",
                padding:theme.value(5)
                ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    padding:theme.value(3),
                }
            }}>{label}</Typo>}
            
            {state && <Box sx={{whiteSpace:"pre",overflowX:"auto",color:"greenyellow", fontFamily:"monospace",padding:"10px",backgroundColor:"rgba(0,0,0,.3)"}}>
                  {JSON.stringify(value,null,"\t")}
            </Box>}

    </Box>
}