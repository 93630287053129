import styled from "styled-components"

/*| SystemCssProperties<Theme>
  | CSSPseudoSelectorProps<Theme>
  | CSSSelectorObjectOrCssVariables<Theme>
  | null;*/

function createCSSFromObject(css: React.CSSProperties): string {
    let rules = "";
    for (let i in css) {
      if (!(css as any)[i]) continue;
      const name = i.replaceAll(/([A-Z])/g, "-$1").toLowerCase();
      const param = (css as any)[i];
      if(typeof param === "object"){
        rules+=`${name}{\n\t`+createCSSFromObject(param)+`};\n`
      }else
        rules += `${name}: ${param};\n`;
    }
    return rules;
}

interface IBoxCss extends React.CSSProperties{
    [key:string]:any
}

export interface IBoxProps{
    children?:any
    sx?:IBoxCss
    onClick?:(e?:MouseEvent)=>void,
    element?:"div"|"input"|"a",
    variant?:string,
    type?:string
    value?:string
    title?:string
    style?:React.CSSProperties
    vflex?:boolean,
    cflex?:boolean
}

interface IBoxDivProps{
    $sx?:React.CSSProperties
}
const BoxDiv=styled.div<IBoxDivProps>`
    box-sizing: border-box;
    ${({$sx})=>{return $sx?createCSSFromObject($sx):"";}}
`

const BoxInput=styled.input<IBoxDivProps>`
    box-sizing: border-box;
    ${({$sx})=>{return $sx?createCSSFromObject($sx):"";}}
`

export const Box=(props:IBoxProps)=>{
    const {children,sx,title,style} = props
    const data:any = {}
    for(let i in props){
        if(i.indexOf("data-")===0)
            data[i]=(props as any)[i]
    }

    let tsx=sx;
    if(props.vflex){
        if(!tsx)
            tsx={}
        tsx.display="flex"
        tsx.flexDirection="column"
    }

    if(props.cflex){
        if(!tsx)
        tsx={}
        tsx.display="flex"
        tsx.flexDirection="column"
        tsx.alignItems="center"
        tsx.justifyContent="center"

    }
  
    
    
    if(props.element && props.element==="input"){
        return <BoxInput {...data} style={style} onClick={props.onClick} $sx={tsx} type={props.type} onChange={()=>{}} value={props.value}/>
    }

    return <BoxDiv title={title} style={style} {...data} onClick={props.onClick} $sx={tsx}>{children}</BoxDiv>
}