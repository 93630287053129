import { useState } from "react"
import { Box } from "./box/Box"
import { Typo } from "./text/Typo"
import { TextInput } from "./input/TextInput"
import { link } from "fs"

interface IDatePickerProps{
    value:Date|string|number|[Date,Date],
    label?:string,
    type:"day"|"month"|"range"
    onChange:(value:string|number|[Date,Date])=>void
}

export const DatePicker = ({value,label,type,onChange}:IDatePickerProps) =>{
    if(typeof value === "string" || typeof value === "number")
        value = new Date(value)
    if(Array.isArray(value)){
        type="range"
        return <Box>RANGE</Box>
    }
    return <TextInput label={label ?? "DATE"} type={type} value={value.toDateString()} onChange={onChange}/>
}