import { useTheme } from "styled-components"
import { Box } from "./box/Box"
import { Burger } from "./Burger";
import { Typo } from "./text/Typo";
import { Spacer } from "./Spacer";
import { useState } from "react";


export const TabMenu = ({menu,onChange,selected,small,burger}:{burger?:boolean,small?:boolean,menu:{id:string,label:string}[],selected:string,onChange:(val:string)=>void}) =>{
    const theme = useTheme();
    const [open,setOpen] = useState<boolean>(false);
    if(burger){
        const selectedItem = menu.find(val=>val.id===selected)
        return (
            <Box sx={{
                flexGrow:1,
            }}>
            <Box sx={{
                display:"flex",
                alignItems:"center",
                gap:theme.value(3),
                width:"100%",
            }}>
                <Typo uppercase variant="big">{selectedItem?.label}</Typo>
                <Spacer grow/>
                <Burger onClick={()=>setOpen(!open)} open={open}/>
            
            </Box>

            
            {open && <Box sx={{
                position:"absolute",
                zIndex:100,
                display:"flex",
                width:"100%",
                flexDirection:"column",
                backgroundColor:"rgba(0,0,0,.5)",
                backdropFilter:"blur(15px)",
                left:"0px",
                marginTop:"10px",
                flexGrow:1,
                borderBottom:"1px solid rgba(255,255,255,.1)",
                borderTop:"1px solid rgba(255,255,255,.1)",
                "&>div":{
                    padding:"12px 20px",
                    cursor:"pointer",
                    fontSize:"14px",
                    transition:"background-color .3s, color .3s",
                    textTransform:"uppercase",
                    "&:hover":{
                        backgroundColor:"rgba(0,0,0,.3)",
                        color:"#33a0ff"
                    }
                },
                "&>div[data-selected=true]":{
                    backgroundColor:"rgba(0,0,0,.6)",
                    pointerEvents:"none"

                    
                }
            }}>
                {menu.map((val,i)=>{
                    if(selected === val.id)
                        return null;
                    return <div onClick={()=>{
                    setOpen(!open)
                    onChange(val.id)
                }} key={i} data-selected={selected===val.id}>{val.label}</div>})}
            </Box>}
            </Box>
        );

    }


    return <Box sx={{
        display:"flex",
        gap:small?theme.value(3):theme.value(5)
        ,"&>div":{
            textTransform:"uppercase",
            padding:small?"6px":theme.value(2),
            borderBottom:"2px solid rgba(0,0,0,.2)",
            cursor:"pointer",
            fontSize:small?"14px":"auto"
            ,"&[data-selected='true']":{
                color:"#33a0ff",
                borderBottom:"2px solid #33a0ff",
                pointerEvents:"none",
            }
        }
    }}>
        {menu.map((val,i)=><div onClick={()=>onChange(val.id)} key={i} data-selected={selected===val.id}>{val.label}</div>)}
    </Box>
}