import styled, { keyframes, useTheme } from "styled-components"
import { Box } from "./box/Box"
import { Typo } from "./text/Typo"
import { Divider } from "./Divider"



interface IPanelProps{
    children?:any,
    sx?:React.CSSProperties,
    label?:string
    busy?:boolean
    busyType?:"bar"|"spinner"
}


const barAnimation = keyframes`
    0% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
`

const busyAnimation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }

`

const ProgressBarDiv = styled.div<{$color:string}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 2px;
    
    &>div{
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${props=>props.$color};
        animation: ${barAnimation} 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
`

export const ProgressBar = () =>{
    return <ProgressBarDiv $color="#3cb4ff">
        <div></div>
    </ProgressBarDiv>
}


export const Panel = ({children,sx,label,busy,busyType}:IPanelProps) =>{
    const theme = useTheme()
   
    return <Box vflex sx={{
        position:"relative",
        backgroundColor:theme.colors.panelBgColor,
        boxShadow:"0px 2px 8px rgba(0,0,0,.1)",
        borderRadius:theme.values.borderRadius,
        ...sx
    }}>

        {busy && <Busy type={busyType ?? "spinner"}/>}

        {label && <><Typo variant="head" weight="500" block sx={{padding:theme.value(5)}}>
                {label}
            </Typo>
            <Divider />
            </>}
        <Box vflex sx={{
            flexGrow:1,
            padding:theme.value(5)
            
        }}>
            {children}
        </Box>
    </Box>
}


const BusyDiv = styled.div`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,.3);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: ${busyAnimation} 0.2s linear;
    gap:15px;
    flex-direction: column;
`


const Busy = ({type}:{type:string}) =>{

    if(!type || type==="spinner"){
        return <BusyDiv>
            <Spinner/>
            <Typo variant="caption" weight="500">LOADING</Typo>
        </BusyDiv>
    }else{
        return <Box sx={{
            position:"absolute",
            top:"-12px",
            left:"0",
            width:"100%",
            height:"10px",
            zIndex:1000,
        }}>
       
            <ProgressBar />
        </Box>
    }


        
    
}



const spinnerAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const SpinnerDiv = styled.div<{$color:string,$size:number}>`
    display: inline-block;
    position: relative;
    width: ${props=>props.$size}px;
    height: ${props=>props.$size}px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &>div{
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 90%;
        height: 90%;
        border: ${props=>Math.round(props.$size*.1)}px solid ${props=>props.$color} ;
        border-radius: 50%;
        animation: ${spinnerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${props=>props.$color} transparent transparent transparent;
    }

    &>div:nth-child(1) {
        animation-delay: -0.45s;
    }
    &>div:nth-child(2) {
        animation-delay: -0.3s;
    }
    &div:nth-child(3) {
        animation-delay: -0.15s;
    }
`

export const Spinner=({color,size=40}:{color?:string,size?:number})=>{
    return <SpinnerDiv $size={size} $color={color?color:"#fffc5f"}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </SpinnerDiv>
}