import Signal, { Req } from "badmfck-signal";
import axios,{AxiosError} from "axios";
import { Config, IScheme, REQ_HOST } from "../Config";
import sha256  from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import { REQ_API } from "./Api";

export interface ILoginForm{
    login:string,
    password:string,
    remember:boolean,
    server:IScheme|null
}
export interface IProfile{
    name:string,
    token:string
    avatar?:string|null
}

let profile:IProfile|null=null;

export const S_AUTH_ERROR=new Signal<{code:number,message:string,resolve?:any}|null>()
export const S_AUTH_COMPLETE=new Signal<IProfile|null>()
export const S_AUTH_REQUEST=new Signal<ILoginForm>();
export const S_AUTH_BUSY=new Signal<boolean>();
export const REQ_AUTH_PROFILE=new Req<void,IProfile|null>();
export const S_SIGN_OUT=new Signal<void>();
export const S_AUTH_ERROR_ON_CALL = new Signal<{code:number,message:string,resolve?:any}|null>()

export const checkLocalAuth=()=>{
    const cred = localStorage.getItem("cred")
    const server = localStorage.getItem("server");
    if(!cred || !server)
        return;
    REQ_HOST.request(JSON.parse(localStorage.getItem("server")!).URL_API)
    doLogin(cred,JSON.parse(server),true)
}

REQ_AUTH_PROFILE.listener=async ()=>profile

S_AUTH_ERROR_ON_CALL.subscribe((err)=>{

    // Try to relogin
    const cred = localStorage.getItem("cred")
    const server = localStorage.getItem("server")

    if(cred && server && err){
        doLogin(cred,JSON.parse(server),true,err.resolve)
        return;
    }

})

S_AUTH_ERROR.subscribe((err)=>{
    localStorage.removeItem("cred")
    localStorage.removeItem("server")
    S_AUTH_COMPLETE.invoke(null)
})

S_SIGN_OUT.subscribe(()=>{
    localStorage.removeItem("cred")
    localStorage.removeItem("server")
    window.location.reload();
});



S_AUTH_REQUEST.subscribe(async (req)=>{

    if(!req.login || req.login.length<3 || !req.password || req.password.length<6){
        S_AUTH_ERROR.invoke({code:-1,message:"Check your login and password"})
        return;
    }


    if(!req.server){
        S_AUTH_ERROR.invoke({code:-2,message:"Please select server"})
        return;
    }

    const credentials = CryptoJS.SHA256(req.login).toString(CryptoJS.enc.Base64)+"@"+CryptoJS.SHA256(req.password).toString(CryptoJS.enc.Base64)

   doLogin(credentials,req.server,req.remember)

})

const doLogin=async (credentials:string,server:IScheme,remember:boolean,resolve?:any)=>{
    S_AUTH_BUSY.invoke(true)
    S_AUTH_ERROR.invoke(null);

    let response=null;

    try{
        response = await axios.post(server.URL_API+"login",{
            credentials:credentials,
        });
    }catch(e){
        const err = e as AxiosError;
        if(err.response && err.response.data && (err.response.data as any).error){
            S_AUTH_ERROR.invoke((err.response.data as any).error)
        }else{
            S_AUTH_ERROR.invoke({code:-2,message:"can't authenticate, check your network & credentials"})
        }
    }
    if(response && response.data ){
        if(response.data.error){

           localStorage.removeItem("cred");
           S_AUTH_ERROR.invoke((response.data as any).error)
        }else{
            // setup auth
             profile={
                name:response.data.data.name,
                token:response.data.data.token,
                avatar:response.data.data.avatar
            }
            if(remember){
                console.log("Remember me",credentials)
                
                localStorage.setItem("server",JSON.stringify(server))
                localStorage.setItem("cred",credentials)
            }

            S_AUTH_COMPLETE.invoke(profile)
            if(resolve){
                setTimeout(async ()=>{
                    const response=await REQ_API.request(resolve.request);
                    resolve.callback(response);    
                },1000)
                
            }
        }
    }

    S_AUTH_BUSY.invoke(false)

}
