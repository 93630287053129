import { useEffect } from "react"
import { Box } from "./components/box/Box"
import { LoginForm } from "./forms/LoginForm"
import { S_AUTH_COMPLETE, checkLocalAuth } from "./managers/Auth"
import { Yapily } from "./managers/Yapilly"
import { MainPanel } from "./panels/MainPanel"
import { TransactionPanel } from "./panels/TransactionPanel"
import { YapilyBankGroupPanel } from "./panels/YapilyBankGroupPanel"
import { YapilyBankPanel } from "./panels/YapilyBankPanel"
import { YapilyForceFinalizeSessionPanel } from "./panels/YapilyForceFinalizeSessionPanel"


export const App = () =>{

    const profile = S_AUTH_COMPLETE.use([])
    useEffect(()=>{
        checkLocalAuth()
    },[])

    if(!profile)
        return <LoginForm />
    
    return <Box sx={{position:"relative"}}>
        <MainPanel/>
        <TransactionPanel />
        <YapilyBankPanel/>
        <YapilyBankGroupPanel/>
        <YapilyForceFinalizeSessionPanel />
       
    </Box>
}
