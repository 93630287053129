import { Box } from "./box/Box"

interface ISpacerProps{
    grow?:boolean
    size?:string
}

export const Spacer=({grow,size}:ISpacerProps)=>{
    let sizecss:React.CSSProperties|null=null;
    if(size){
        sizecss={
            minHeight:size,
            maxHeight:size,
            height:size,
            minWidth:size,
            maxWidth:size,
            width:size,
        }
    }
    return <Box sx={{
        flexGrow:grow?"1":undefined,
        ...sizecss
    }}></Box>
}