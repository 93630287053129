import { useState } from "react";
import { ILoginForm, S_AUTH_ERROR, S_AUTH_REQUEST } from "../managers/Auth";
import { FormConstructor } from "./FormConstructor";
import { Config, REQ_HOST } from "../Config";

export const LoginForm = () =>{
    const [form,setForm] = useState<ILoginForm>({
        login:"",
        password:"",
        remember:false,
        server:null
    })

    const [hosts,setHost]=REQ_HOST.useRequest()
    const error = S_AUTH_ERROR.use([])
    const sendForm = () =>{
        console.log(form)
        if(!form.server)
            return;
        setHost(form.server?.URL_API)
        S_AUTH_ERROR.invoke(null)
        S_AUTH_REQUEST.invoke(form);
    }

    if(hosts && form.server===null){
        form.server = hosts.find(val=>val.selected) ?? null
        setForm({...form})
    }

    return <FormConstructor onSubmit={()=>sendForm()} error={error} title={"Authenticate"} form={form} fieldsGroups={[
        {
            fields:[
                {
                    label:"Login",
                    name:"login",
                    type:"login",
                },
                {
                    label:"Password",
                    name:"password",
                    type:"password",
                },
                {
                    label:"Server",
                    name:"server",
                    type:"select",
                    values:hosts,
                    titleFieldName:"title",
                    value:form.server
                },
                {
                    label:"Remember me",
                    name:"remember",
                    type:"checkbox",
                }

            ]
        }
    ]} />

}
