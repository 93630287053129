import { Box} from "@mui/material";
import { REQ_AUTH_PROFILE, S_SIGN_OUT } from "../managers/Auth"
import { SessionsPanel } from "./SessionsPanel";
import { useState } from "react";
import { TerminalsPanel } from "./TerminalsPanel";
import { LogPanel } from "./LogPanel";
import { NetlogPanel } from "./NetlogPanel";
import { ServerSelector } from "../components/ServerSelector";
import { BlocksPanel } from "./BlocksPanel";
import { AddBlockForm } from "../forms/AddBlockForm";
import { useTheme } from "styled-components";
import { YapilyPanel } from "./YapilyPanel";
import { isMobile } from "react-device-detect";
import { Typo } from "../components/text/Typo";



export const MainPanel = () =>{

    const theme = useTheme();
    const [page,setPage] = useState<string>("sessions");

    const user = REQ_AUTH_PROFILE.use();
    if(!user)
        return null;

   
    let content=null;
    if(page==="sessions"){
        content = <SessionsPanel/>
    }

    /*<div><SearchSessionForm/>
        <StatPanel/>*/
    if(page==="terminals")
        content = <TerminalsPanel/>
    if(page==="log")
        content = <LogPanel/>
    if(page==="netlog")
        content = <NetlogPanel/>
    if(page ==="blocks")
        content = <BlocksPanel />
    if(page ==="yapily")
        content = <YapilyPanel />
    

    let i =0;

    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"max-content 1fr",
        //gap:theme.value(1),
        position:"relative",
        height:"100vh"
    }}>
        {
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            top:0,
            borderRight:"1px solid rgba(255,255,255,.1)",
            padding:theme.values.padding
        }}>
            
            <Box sx={{
                display:"flex",
                gap:"10px",
                flexDirection:"column",
                alignItems:"center",
                padding:theme.values.padding,
                borderBottom:"1px solid rgba(255,255,255,.1)"
            
            }}>
            
            {/*AVA*/<Box sx={{
                width:"50px",
                height:"50px",
                borderRadius:"50%",
                backgroundImage:user.avatar && `url(${user.avatar})`,
                backgroundColor:"rgba(0,0,0,.3)",
                backgroundSize:"cover",
                backgroundPosition:"center",
            }} />}
                <Typo variant="small" color="white" align="center" sx={{
                    maxWidth:"60px",
                }}>{user.name}</Typo>
            </Box>

            <Box sx={{
                display:"flex",
                flexDirection:"column",
                
                '&>div':{
                    cursor:"pointer",
                    fontSize:"12px",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",
                    paddingTop:"calc(80% - 10px)",
                    paddingBottom:"10px",
                    borderTop:"1px solid rgba(255,255,255,.1)",
                    borderBottom:"1px solid rgba(0,0,0,.6)",
                    transition:"background-color .3s",
                    "&:hover":{
                        backgroundColor:"rgba(69, 48, 105, 0.3)"
                    },
                    "&[data-selected=true]":{
                        color:"#FFCA00",
                        fontWeight:"bold",
                        //backgroundColor:"rgba(69, 48, 105, 0.3)"
                    }
                }
            }}>
                <div data-selected={page==="sessions"} onClick={()=>setPage("sessions")}>SESSIONS</div>
                <div data-selected={page==="netlog"} onClick={()=>setPage("netlog")}>NETLOG</div>
                <div data-selected={page==="log"} onClick={()=>setPage("log")}>LOG</div>
                <div data-selected={page==="yapily"} onClick={()=>setPage("yapily")}>YAPILY</div>
                <div onClick={()=>S_SIGN_OUT.invoke()}>SIGN OUT</div>
 
            </Box>
        </Box>}
        
        <Box sx={{
            overflow:"auto",
            height:"100%",
        }}>
            {content}
        </Box>

   

    </Box>
}


/*
 <div onClick={()=>setPage("terminals")}>TERMINALS</div>
                <div onClick={()=>setPage("netlog")}>NETLOG</div>
                <div onClick={()=>setPage("log")}>LOG</div>
                <div onClick={()=>setPage("blocks")}>BLOCKS</div>
                <div onClick={()=>setPage("yapily")}>YAPILY</div>

                */