import { useEffect, useState } from "react"
import { IStat, IStatRequest, REQ_STAT } from "../managers/Stat"
import { Panel } from "../components/Panel";
import { Typo } from "../components/text/Typo";
import { Box } from "../components/box/Box";
import { useTheme } from "styled-components";
import { Button } from "../components/Button";
import { Spacer } from "../components/Spacer";

import dayjs from "dayjs";
import { TabMenu } from "../components/TabMenu";
import { DatePicker } from "../components/DatePicker";
import { Divider } from "../components/Divider";

const colors:Record<string,string>={
    "processed":"#1389ff",
    "expired":"#7540a5",
    "completed":"#6cc38a",
    "error":"#c51e7a",
    "fail":"#c51e7a",
    "abandoned":"#caab64",
    "processing":"#1389ff",
    "created":"#b4c0b7",
}

const defaultFilter:IStatRequest = {
    dateFilterType:"bymonth",
    date:new Date().toUTCString()
}

export const StatPanel = ()=>{
    const theme = useTheme()
   
    const [filter,setFilter] = useState<IStatRequest>(defaultFilter)
    const [page,setPage] = useState<string>("countries");
    const [statPage,setStatPage] = useState<string>("ap");
    const [dateFilterType,setDateFilterType] = useState<string>("bymonth");
    const [s,reqStat,setStat,busy] = REQ_STAT.useRequest()

    useEffect(()=>{
        reqStat(filter); 
    },[])

    
   
    

    
    let error = null;
    if(s && typeof s !=="object")
        error = "Wrong response format"

    if(s && "code" in s)
        error = "ERROR GETTING STAT: {stat.message}"

    let stat=null
    if(!error)
    stat = s as IStat

    let barGraph = null;
    if(stat){
        switch(page){
            case "countries":
                barGraph = <StatBarBlock name="Countries" data={stat.data.byCountry.data} />
            break;

            case "branches":
                barGraph = <StatBarBlock name="Branches" data={stat.data.byBranch.data} />
            break;

            case "terminals":
                barGraph = <StatBarBlock name="Terminals" data={stat.data.byTerminal.data} />
            break;

            case "ps":
                barGraph = <StatBarBlock name="Payment systems" data={stat.data.byPaymentSystem.data} />
            break;

            case "statuses":
                barGraph = <StatBarBlock name="Statuses" data={stat.data.byStatus.data} />
            break;
        }
    }

    let dateFilter = <DatePicker label="Period" onChange={date => {
        /*const f = {...filter,date:new Date(date).toUTCString(),dateFilterType:dateFilterType}
        setFilter(f);
        reqStat(f);*/
    } } value={new Date()} type={"range"} />;
 

    // DE22200704240053216800
    // LC87UL5E
    
    return <Panel label={"REPORTING / "+(stat?.table?.replace("_",".") ?? "----.--")} busy={busy}>

        {/*<TabMenu small menu={[
                {id:"byday",label:"BY DATE"},
                {id:"bymonth",label:"BY MONTH"},
                ]}
                selected={dateFilterType}
                onChange={val=>setDateFilterType(val as any)}
             />
<Spacer size={theme.value(5)} />
        {dateFilter} <Spacer size={theme.value(5)} />
            <Divider/>*/}
        
        {stat && !error && <>
           
            
            <Spacer size={theme.value(5)} />
       

            <TabMenu burger small menu={[
                {id:"countries",label:"COUNTRIES"},
                {id:"branches",label:"BRANCHES"},
                {id:"terminals",label:"TERMINALS"},
                {id:"ps",label:"PAYMENT SYSTEM"},
                {id:"statuses",label:"STATUSES"}
                ]}
                selected={page}
                onChange={val=>setPage(val as any)}
             />
        
            {barGraph}
    
            <Spacer size={theme.value(5)} />
            <Divider/>
            <Spacer size={theme.value(5)} />

            {stat.data.statusByPaymentSystem?.length && <>
                <TabMenu burger small menu={stat.data.statusByPaymentSystem.map(val=>{
                    if(!val.data || !val.data.length)
                        return {id:"error",label:"ERROR"};
                    return {id:val.data[0].payment_system,label:val.data[0].label ?? val.data[0].payment_system}})}
                    selected={statPage}
                    onChange={val=>setStatPage(val as any)}
                />
            </>}

            <Spacer size={theme.value(5)} />

            {stat.data.statusByPaymentSystem && stat.data.statusByPaymentSystem.map((val,i)=>{

                if(!val.data || val.data.length===0)
                    return null;

                if(val.data[0].payment_system!==statPage)
                    return null;

                
                
                return <Box key={i}>

                    <Box sx={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"2px"
                    }}>
                    {val.data && val.data.map((v,j)=>{
                        
                        // find total
                        const total = val.data.find(v=>v.status==="total")?.count ?? 0;
                        let percent = Math.round((v.count/total)*100);
                        if(percent<2)
                            percent=2;

                        if(v.status==="conversion" || v.status==="total")
                            return null;

                        return <Box key={j}title={v.status+" - "+v.count}
                        sx={{
                            backgroundColor:"rgba(0,0,0,.2)",
                            width:"100%",
                            borderRadius:"0 5px 5px 0",
                            overflow:"hidden",
                            position:"relative"
                            //display:"flex",
                            //flexDirection:"column",
                           // alignItems:"flex-end"
                        }}><Box  sx={{
                           // position:"absolute",
                            width:percent+"%",
                            height:"12px",
                            backgroundColor:colors[v.status] ?? "#FFCCAA",
                            //opacity:"0.7",
                            borderRadius:"0 5px 5px 0",
                            bottom:"0px",
                        }}></Box></Box>
                    })}
                    </Box>
                    <Spacer size={theme.value(5)} />
                    <Box sx={{
                        display:"grid",
                        gridTemplateColumns:"repeat( auto-fit, minmax(110px,1fr) )",
                        gap:theme.value(3)
                    }}>
                    {val.data && val.data.map((v,j)=>{
                        return <StatBlock key={j} name={v.status} value={v.count+(v.status==="conversion"?"%":"")} />
                    })}
                    </Box>
                </Box>
            })}
  



        </>}

        
        <Spacer size={theme.value(5)} />

        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:theme.value(5)}}>
            <Typo variant="small">UPDATED AT:</Typo> <Typo color="white" variant="small">{dayjs(stat?.updated).format("HH:mm:ss")}</Typo>
            <Spacer grow />
            <Button size="small" disabled={busy} variant="filled" onClick={()=>{
                reqStat({...filter});
            }}>{busy?"LOADING...":"REFRESH"}</Button>
        </Box>
     </Panel>
}


const HorizontalStatBar = ({name,value,percent,num}:{name:string,value:number,percent:number,num:number})=>{
    const theme = useTheme();
    if(percent<2)
        percent=2;
    const barHeight = "4px"

   
    const barColor = percent>5?"rgb(0, 221, 255)":"rgb(0, 119, 255)"
    
    return <Box sx={{
            display:"contents",
        }}>
        <Typo variant="small"  sx={{
            //backgroundColor:"rgba(0,0,0,.3)",
            padding:"6px 10px",
            color:"#ffe1a4",
            borderRadius:"5px",
        }} block uppercase>{name ?? "??"}</Typo>
        <Box sx={{
            flexGrow:1,
            backgroundColor:"rgba(0,0,0,.4)",
            height:barHeight,
            borderRadius:"3px"
        }}>
            <Box sx={{
                background:barColor,
                height:"100%",
                borderRadius:"3px",
                width:`${percent}%`
            }}></Box>
        </Box>
        <Typo variant="small" color="white" align="right" sx={{}}>{value}</Typo>
    </Box>
}


export const StatBlock = ({sx,name,value,small,bold,noborder,danger,color}:{color?:string,danger?:boolean,sx?:React.CSSProperties,noborder?:boolean,name:string,value:string|number,small?:boolean,bold?:boolean})=>{
    const theme = useTheme();
    return <Box vflex sx={{
        //backgroundColor:theme.colors.panelBgDarken,
        padding:small?"10px":theme.values.padding,
        borderRadius:theme.values.borderRadius,
        borderBottom:noborder?undefined:"2px solid #a0489533",
        gap:small?"3px":"6px",
        ...sx
    }}>
        <Typo variant="small" color="rgba(255,255,255,.5)" uppercase block>{name}:</Typo>
        <Typo color={color?color:danger?"#e91e63":(bold?"rgba(255,255,255,1)":"rgba(255,255,255,.8)")} weight={bold?"bolder":undefined} variant={small?undefined:"big"} block>{value}</Typo>
    </Box>
}



const StatBarBlock = ({name,data}:{name:string,data:{count:number,percent:number,label:string}[]})=>{
    const theme = useTheme();
    const [more,setMore] = useState(false);

   return <><Spacer size={theme.value(5)}/>
            <Box sx={{
                position:"relative",
                width:"100%",
                display:"grid",
                gridTemplateColumns:"max-content 1fr max-content",
                alignItems:"center",
                columnGap:theme.value(3),
                rowGap:theme.value(1)
            }}>
                {data && data.map((val,i)=>{
                    if(!more && i>10)
                        return null;
                    return <HorizontalStatBar key={i} num={i} name={val.label} value={val.count} percent={val.percent} />
                })}
            </Box>
            {data && data.length > 10 && <>
                <Spacer size={theme.value(3)}/>
                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:theme.value(5)}}>
                    <Spacer grow />
                    <Button size="small"  onClick={()=>setMore(!more)}>{more?"LESS":"MORE (+"+(data.length-10)+")"}</Button>
                </Box>
            </>}</>

}