import { useEffect, useState } from "react";
import { IFilter, ISession, REQ_SESSIONS, S_SESSION_LOADED } from "../managers/Sessions"
import dayjs from "dayjs";
import { Price } from "../components/Price";
import { Button } from "../components/Button";
import { Box } from "../components/box/Box";
import { Typo } from "../components/text/Typo";
import { useTheme } from "styled-components";
import { Panel } from "../components/Panel";
import { StatPanel } from "./StatPanel";
import { TextInput } from "../components/input/TextInput";
import { Spacer } from "../components/Spacer";
import { DatePicker } from "../components/DatePicker";

const isMobile=false;


const statColor:{[key:string]:string}={
    "completed":"#06ef96",
    "fail":"#e91e63",
    "error":"#b72556",
    "canceled":"#795548",
    "processing":"#2196f3",
    "problem":"#9c27b0",
    "abandoned":"#ff9800",
    "processed":"#21f3db",
}



export const SessionsPanel=()=>{
    
    
   
    const [filter,setFilter] = useState<IFilter>({
        search:"",
        ap:true,
        gp:true,
        ob:true,
        re:true,
        pr:true,
        mid:0,
        ignoreEmptyRequests:false,
        ts:0,
        date:+new Date()
    })
    const[refresh,setRefresh] = useState<number>(Math.round(+new Date()/1000))

   
    const [sessions,sendSessionRequest,setLocalSessionRequest,busy] = REQ_SESSIONS.useRequest(()=>setRefresh(Math.round(+new Date()/1000)));


    const theme = useTheme();
    
    const onRefreshClick = () =>{
        if(busy)
            return;

        sendSessionRequest({...filter,ts:Math.round(new Date().getTime()/1000)})
    }

    const onSearchClick=(search:string)=>{
        if(search.length<3 && search.length>255){
            console.error("Search string wrong")
            return;
        }
        filter.search=search;
        setFilter(filter);
        sendSessionRequest({...filter})
    }

    const onResetClick=()=>{
        setFilter({
            search:"",
            ap:true,
            gp:true,
            ob:true,
            re:true,
            pr:true,
            mid:0,
            ignoreEmptyRequests:false,
            date:+new Date(),
            ts:0
        });
        sendSessionRequest({...filter})
    }

   useEffect(()=>{
        const i = setInterval(()=>{
            onRefreshClick();
        },1000*60)
        return () =>{clearInterval(i)}
    },[])

    /* */
    return <Box sx={{
            padding:theme.value(5),
            display:"grid",
            gridTemplateColumns:"minmax(800px,1fr) 0.4fr",
            flexWrap:"wrap",
            height:"100%"
            ,gap:theme.value(5)
            ,width:isMobile?"100vw":undefined
            ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                gridTemplateColumns:"1fr",
            }
        }}>
            {/*LEFT*/}

            
            <Panel busy={busy} label="AP/GP/OB Sessions">
                    
                    <Box sx={{
                        display:"flex",
                        flexWrap:"wrap",
                        flexDirection:!isMobile?"row":"column",
                        gap:theme.value(5),
                    }}>
                        
                        {!isMobile && <>
                        <PaymentSystem filter={filter} onChange={f=>{
                            setFilter(f)
                            sendSessionRequest(f)
                        }}/>

                        {(filter.ob || filter.re) && <>
                        <EmptyRequests filter={filter} onChange={f=>{
                            setFilter(f)
                            sendSessionRequest(f)
                        }}/>
                        </>}

                        <DatePicker type="range" label="Period" value={filter.date??new Date()} onChange={date=>{
                            /*const f  = {...filter,date:+new Date(date)} 
                            console.log(f);
                            setFilter(f)
                            sendSessionRequest(f)*/
                        }}/>


                        <TextInput sx={{maxWidth:"100px"}} type="text" value={filter.mid+""} label="Merchant id" onChange={value=>{
                            const f = {...filter,mid:(parseInt(value)??0)}
                            setFilter(f)
                        }}/>

                        <TextInput sx={{flexGrow:1,flex:"1 1 auto"}} type="search" label="Search" onSearch={search=>{
                            onSearchClick(search)
                        }} onReset = {onResetClick}/>
                        </>}

                       

                    </Box>

                    
                    <Spacer size={theme.value(8)}/>

                    <Box sx={{
                        flexGrow:"1",
                        overflow:"auto",
                        position:"relative",
                        minHeight:"60vh"
                    }}>
                        <Box sx={{position:"absolute",width:"100%"}}>
                            {sessions && <SessionTable sessions={sessions}/>}
                            
                        </Box>
                        {!sessions && <Box sx={
                                {
                                    display:"flex",
                                    flexDirection:"column",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    height:"100%"
                                }
                            
                            }><Typo>NO DATA</Typo></Box>}
                    </Box>

                    <Spacer size={theme.value(5)}/>

                    <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:theme.value(5)}}>
                        <Typo variant="small">UPDATED AT:</Typo> <Typo color="white" variant="small">{dayjs(refresh*1000).format("HH:mm:ss")}</Typo>
                        <Spacer grow/>
                        <Button disabled={busy} size="small" onClick={onRefreshClick}>{busy?"LOADING...":"REFRESH"}</Button> 
                    </Box>

            </Panel>
            
            

            {/*RIGHT*/}
            <Box vflex sx={{
                gap:theme.value(5),
            }}>

                <StatPanel />
                <Panel>SESSION LOG</Panel>
            </Box>
    </Box>
}




export const SelectedSession = ({session}:{session?:ISession}) =>{
    if(!session)
        return <div>PROBLEM!</div>
    return <Box sx={{
        position:"sticky",
        top:"0",
        display:"block",
        whiteSpace:"pre",
        overflow:"auto"
    }}>{JSON.stringify(session,null,"\t")}</Box>
}

// <Virtuoso style={{ height: '400px' }} totalCount={200} itemContent={index => <div>Item {index}</div>} />


const SessionTable=({sessions}:{sessions:ISession[]})=>{

    const [selected,setSelected]=useState<ISession|null>(null);
    const theme = useTheme();

    return <Box sx={{
        display:"grid",
        position:"relative",
        gridTemplateColumns:"max-content max-content minmax(50px,.6fr) minmax(50px,.6fr) 1fr max-content max-content"
        ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
            //gridTemplateColumns:"0 max-content 0 0 1fr max-content max-content"
            gridTemplateColumns:"0 max-content 0 minmax(50px,.6fr) 1fr max-content max-content"
            ,fontSize:".9em"
        }
    }}>

        <Box sx={{
            display:"contents"

            
            ,'&>div':{
                padding:theme.values.padding,
                borderBottom:"1px solid rgba(0,0,0,.5)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                textTransform:"uppercase",
                zIndex:1,
                backgroundColor:"rgba(0,0,0,.4)",
                fontSize:"12px",
                fontWeight:"600",
                position:"sticky",
                top:"0",
                overflow:"hidden"         
            }
            ,"&>div:nth-child(1)":{
                [`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    visibility:"hidden"
                }   
            }
            ,"&>div:nth-child(3)":{
                [`@media (max-width: ${theme.values.rebuildWidth})`]:{
                   visibility:"hidden"
                }   
            }
            ,"&>div:nth-child(4)":{
                [`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    //visibility:"hidden"
                }   
            }

        }}>
            <div>Country</div>
            <div>trx id</div>
            <div>terminal</div>
            <div>user</div>
            <div>source</div>
            <div>amount</div>
            <div>status</div>
            
        </Box>


        {sessions.map(val=>{

            const isTest = val.transaction_id.indexOf("111110")!==-1 || val.terminal==="test_psys";
            
        return <Box sx={{
            display:"contents",
            fontWeight:selected === val ? "bold":undefined,
            '&>div':{
                padding:theme.values.padding,
                borderBottom:"1px solid rgba(0,0,0,.3)",
                borderTop:"1px solid rgba(255,255,255,.07)",
                cursor:"pointer",
                display:"flex",
                gap:"5px",
                flexDirection:"column",
                justifyContent:"center",
                position:"relative",
                transition:"background-color .2s",
                whiteSpace:"nowrap",
                overflow:"hidden",
                opacity:isTest?.5:1,
            },

           

            '&>div:first-child':{
                borderLeft:"4px solid "+statColor[val.status]
            }

            ,"&>div:nth-child(1)":{
                [`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    visibility:"hidden"
                }   
            }

            ,"&>div:nth-child(3)":{
                [`@media (max-width: ${theme.values.rebuildWidth})`]:{
                   visibility:"hidden"
                }   
            }
            ,"&>div:nth-child(4)":{
                [`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    //visibility:"hidden"
                }   
            }
  
            ,'&:hover>div':{
                backgroundColor:"rgba(0,0,0,.3)"
            }
        }} key={val.ssid} onClick={()=>{ 
            S_SESSION_LOADED.invoke(val)
            //setSelected(val)
            }}>

                <div title={val.country && val.country.toUpperCase()}>
                    <Box sx={{
                        backgroundSize:"cover",
                        backgroundPosition:"center",
                        minWidth:"20px",
                        minHeight:"20px",
                        maxWidth:"20px",
                        maxHeight:"20px",
                        backgroundColor:"rgba(0,0,0,.3)",
                        borderRadius:"40%",
                        alignSelf:"center"
                    }}
                    
                    style={{
                        backgroundImage:val.country && `url(https://flagcdn.com/${val.country}.svg)`
                    }}

                    />
                </div>

                <div>
                    <Typo variant="small">{val.ctime.toUpperCase()}</Typo>
                    <Typo variant="caption">{val.transaction_id}</Typo>
                </div>
                
                
                <div>
                    <Typo variant="small">{val.branch.toUpperCase()}</Typo>
                    <Typo variant="caption">{val.terminal}</Typo>
                </div>

                <div>
                    <Typo variant="small">{val.ip ?? "??.??.??.??"}</Typo>
                    <Typo variant="caption">{val.email}</Typo>
                </div>

                <div>
                    <Typo variant="small">{val.paymentSystem}{val.institution?" - "+val.institution:""}</Typo>
                    <Typo variant="caption"> {val.card_mask ?? "..."}</Typo>
                </div>
                
                <Box sx={{backgroundColor:"rgba(0,0,0,.1)"}}><Price amount={val.amount} currency={val.currency}/></Box>
                
                <Box sx={{backgroundColor:"rgba(0,0,0,.2)"}}>
                    <Typo variant="small" sx={{textTransform:"uppercase",fontWeight:"bold",color:statColor[val.status]}}>{val.status}</Typo>
                </Box>
            </Box>
        })}
        
    </Box>
}


// ER
const EmptyRequests = ({filter,onChange}:{filter:IFilter,onChange:(f:IFilter)=>void}) =>{

    const theme = useTheme();
    return <Box sx={{
        display:"flex",
        flexDirection:"column",
        gap:"5px",
    
    }}>
        <Typo variant="small">EMPTY REQUESTS</Typo>
    
        <Box sx={{
                display:"flex",
                gap:theme.value(5)
                ,"&>div":{
                    textTransform:"uppercase",
                    padding:theme.value(2),
                    borderBottom:"2px solid rgba(0,0,0,.2)",
                    cursor:"pointer",
                    fontSize:"12px" 
                    ,"&[data-selected='true']":{
                        color:"#33a0ff",
                        borderBottom:"2px solid #33a0ff",
                        pointerEvents:"none",
                    }
                },
                backgroundColor:"rgba(0,0,0,.1)",
                border:"1px solid rgba(255,255,255,.1)",
                borderRadius:"4px",
                padding:"4px"
                
            }}>
                
            <div data-selected={!filter.ignoreEmptyRequests} onClick={()=>{
                const f = {...filter,ignoreEmptyRequests:false}
                onChange(f);
            }}>SHOW</div> 

            <div data-selected={filter.ignoreEmptyRequests}  onClick={()=>{
                const f = {...filter,ignoreEmptyRequests:true}
                onChange(f);
            }}>IGNORE</div>
            
        </Box>
    </Box>
}

// PS
const PaymentSystem = ({filter,onChange}:{filter:IFilter,onChange:(f:IFilter)=>void}) =>{
    let filterAll=filter.ap && filter.gp && filter.ob && filter.re && filter.pr;
    const theme = useTheme();
    return <Box sx={{
        display:"flex",
        flexDirection:"column",
        gap:"5px",
    
    }}>
        <Typo variant="small">PAYMENT SYSTEM</Typo>
    
        <Box sx={{
                display:"flex",
                gap:theme.value(5)
                ,"&>div":{
                    textTransform:"uppercase",
                    padding:theme.value(2),
                    borderBottom:"2px solid rgba(0,0,0,.2)",
                    cursor:"pointer",
                    fontSize:"12px" 
                    ,"&[data-selected='true']":{
                        color:"#33a0ff",
                        borderBottom:"2px solid #33a0ff",
                        pointerEvents:"none",
                    }
                },
                backgroundColor:"rgba(0,0,0,.1)",
                border:"1px solid rgba(255,255,255,.1)",
                borderRadius:"4px",
                padding:"4px"
                
            }}>
            <div data-selected={!filterAll && filter.ap} onClick={()=>{
                const f = {...filter,ap:true,gp:false,ob:false,re:false,pr:false}
                onChange(f);
                //setFilter(f)
                //sendSessionRequest(f)
            }}>AP</div> 
            <div data-selected={!filterAll && filter.gp}  onClick={()=>{
                const f = {...filter,gp:true,ap:false,ob:false,re:false,pr:false}
                onChange(f);
                //setFilter(f)
                //sendSessionRequest(f)
            }}>GP</div>
            <div data-selected={!filterAll && filter.ob} onClick={()=>{
                const f = {...filter,
                    ob:true,
                    ap:false,
                    gp:false,
                    re:false,
                    pr:false
                }
                onChange(f);
                //setFilter(f)
                //sendSessionRequest(f)
            }}>OB</div>
            <div data-selected={!filterAll && filter.re} onClick={()=>{
                const f = {...filter,
                    ob:false,
                    ap:false,
                    gp:false,
                    re:true,
                    pr:false
                }
                onChange(f);
                //setFilter(f)
                //sendSessionRequest(f)
            }}>RE</div>
            <div data-selected={!filterAll && filter.pr} onClick={()=>{
                const f = {...filter,
                    ob:false,
                    ap:false,
                    gp:false,
                    re:false,
                    pr:true
                }
                onChange(f);
                //setFilter(f)
                //sendSessionRequest(f)
            }}>PR</div>

            <div data-selected={filterAll}  onClick={()=>{
                const f = {...filter,
                    ob:true,
                    ap:true,
                    gp:true,
                    re:true,
                    pr:true
                }
                onChange(f);
                //setFilter(f)
                //sendSessionRequest(f)
            }}>ALL</div>
        </Box>
    </Box>
}