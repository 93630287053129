import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Signal from "badmfck-signal";
import { App } from "./App";
import { Terminals } from "./managers/Terminals";
import { Logs } from "./managers/Logs";
import { NetLogs } from "./managers/Netlog";
import { Blocks } from "./managers/Blocks";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Yapily } from "./managers/Yapilly";
import { isMobile } from "react-device-detect";
import { Api } from "./managers/Api";


Signal.setupReact(useState, useEffect);
new Terminals();
new Logs();
new NetLogs();
new Blocks();
new Yapily();

new Api();

// Dark
const GlobalStyle = createGlobalStyle<any>`
	body{
		padding:0;
		margin:0;
		font-family: 'Roboto', sans-serif;
		font-size: 16px;
		font-weight: 400;
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
    	//text-rendering: optimizeLegibility;
		background-color: ${({theme})=>theme.colors.bgMain};
		color: ${({theme})=>theme.colors.textPrimary};
	}
	
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	
	::-webkit-scrollbar-track {
		background-color:rgba(0,0,0,.2);
		border-radius: 3px;
	}
	
	::-webkit-scrollbar-thumb {
		background-color:rgba(0,0,0,.3);
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color:rgba(0,0,0,1);
	}
`

const darkColor={
	//bgMain:"#241d2c",
	bgMain:"#19141f",
	textPrimary:"#ffffff",
	textSecondary:"rgba(255,255,255,.6)",
	buttonBgColor:"rgb(11, 109, 245)",
	buttonBgColorSecondary:"#740bf5",
	buttonDisabledBgColor:"rgb(65, 81, 103)",
	buttonOutlineColor:"#4e2da0",
	panelBgColor:"#231d2a",
	panelBgDarken:"#1a151d"
}

const input={
	borderRadius:"4px",
	padding:"12px 18px",
	paddingSmall:"10px 14px",
	shadow:"0px 2px 4px rgba(0,0,0,.1)"
}

const values={
	unit:4,
	value16px:"16px",
	borderRadius:"4px",
	padding:"16px",
	rebuildWidth:"1300px"
}

const typos={
	small:{
		fontSize:".8em",
		color:"--textSecondary"
	},
	caption:{
		fontSize:".9em"
	},
	regular:{
		fontSize:"1em"
	},
	big:{
		fontSize:"1.4em",
		color:"--textPrimary"
	},
	headBig:{
		fontSize:"1.3em",
		color:"--textPrimary"
	},
	head:{
		fontSize:"1.2em",
		color:"--textPrimary",
		fontWeight:"300"
	},
	headSmall:{
		fontSize:"1.1em",
		color:"--textPrimary",
		fontWeight:"400"
	}
}


const panelSize=isMobile?{
	height:"100vh",
	width:"100vw"
}:{
    minHeight:"260px",
    minWidth:"390px",
    maxWidth:"80vw",
    maxHeight:"90vh",
}

const darkTheme = {
	input:input,
	colors:darkColor,
	values:values,
	typo:typos,
	panelSize:panelSize,
	value:(i:number)=>{return (i*values.unit)+"px"}
}


ReactDOM.createRoot(document.getElementById("root")!).render(
	//<React.StrictMode>
		<ThemeProvider theme={darkTheme}>
		<GlobalStyle />
			<App />
		</ThemeProvider>
	//</React.StrictMode>
);
