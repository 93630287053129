import { Req } from "badmfck-signal";
import { REQ_API } from "./Api";

export interface INetLogItem{	
	id:number,
	created:number,
	time:number,
	request:{[key:string]:any},
	response:{[key:string]:any}
	error?:string|null
}


export const REQ_NETLOGS = new Req<any,INetLogItem[]>();
export class NetLogs{
    constructor(){
        REQ_NETLOGS.listener = async ()=>await this.getNetLogs()
    }

    async getNetLogs(){
        console.log("GET Logs!")
        try{
            const resp  = await REQ_API.request({
                endpoint:"netlog",
                data:{},
            })
            if(resp.error)
                return resp.error
            return resp.data
        }catch(e){
            return {code:-2,message:"Can't load terminals"}    
        }
    }
}