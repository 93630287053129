import { REQ_LOGS } from "../managers/Logs";
import { Box } from "@mui/material";
import { Panel } from "../components/Panel";
import { Config } from "../Config";
import { Spacer } from "../components/Spacer";
import { Typo } from "../components/text/Typo";
import { useTheme } from "styled-components";
import { Button } from "../components/Button";
import dayjs from "dayjs";


//NGWf1hpyqFIEtPGRh5upBk/LQRJhjg/GxRESz9VVsNk=@RgoeUdi08dvrEq9Pc//DtwWFOOwr3bVL7DkG3RLG4rw=

//JvQwjJMiBqo14UaR8lMJW53oHJE6Opj24TvY7r7y4dg=


//i2orX8D2ZaKn2zp/I7zPotHWgphhmOK8fulWVqKjiDI=

let updated=new Date();

export const LogPanel = () =>{
    //const [busy,setBusy]=useState<boolean>(false);
    const [logs,retakeLogs,resetLogs,busy]=REQ_LOGS.useRequest(()=>updated=new Date())
    const theme = useTheme();
    return  <Box sx={{
        display:"flex",
        flexDirection:"column",
        flexWrap:"wrap",
        height:"100%"
    }}>
    <Panel busy={busy}  sx={{
        flexGrow:1,
        margin:theme.value(5),
    }} label={"LOG/"+Config.scheme.name.toLocaleUpperCase()}>
      

      <Box sx={{
                        flexGrow:"1",
                        overflow:"auto",
                        position:"relative",
                        
                    }}>
                        <Box sx={{position:"absolute",width:"100%"}}>
                            {logs && logs.map(val=>{
                                return <Box sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    "&>div":{
                                        wordBreak:"break-word",
                                    },
                                    borderBottom:"1px solid red",
                                    backgroundColor:val.level+""!=="1"?"red":undefined

                                }} key={val.id}>
                                    <div>{val.source}</div>
                                    <div>{val.date}</div>
                                    <div>{val.level}</div>
                                    <Box sx={{whiteSpace:"pre-wrap"}}>{val.text}</Box>
                                    </Box>
                            })}
                        </Box>
                    </Box>

                    <Spacer size={theme.value(5)}/>

                    <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:theme.value(5)}}>
                        <Typo variant="small">UPDATED AT:</Typo> <Typo color="white" variant="small">{dayjs(updated).format("HH:mm:ss")}</Typo>
                        <Spacer grow/>
                        <Button disabled={busy} size="small" onClick={()=>retakeLogs(+new Date())}>{busy?"LOADING...":"REFRESH"}</Button> 
                    </Box>

        
        
    </Panel></Box>
}