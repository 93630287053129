import Signal, { Req } from "badmfck-signal"
import { REQ_API } from "./Api"

export const REQ_YAPILY_CREATE_SUBAPP = new Req<void,any>()
export const REQ_YAPILY_GET_BANK_LIST = new Req<void,any>()
export const REQ_YAPILY_GET_BANK_GROUPS = new Req<void,any>()
export const REQ_YAPILY_FINALIZE_SESSION = new Req<{ssid:string},any>()

export const S_YAPILY_BANK_GROUP = new Signal<IYapilyBankGroup>()

export class Yapily{
    constructor(){
        console.log("YAPILY!")
        REQ_YAPILY_CREATE_SUBAPP.listener = async ()=>await this.subappCreate()
        REQ_YAPILY_GET_BANK_LIST.listener = async ()=>await this.getBankList()
        REQ_YAPILY_GET_BANK_GROUPS.listener = async ()=>await this.getBankGroups()
        REQ_YAPILY_FINALIZE_SESSION.listener = async (req)=>await this.finalizeSessionWithSuccess(req)
    }

    async finalizeSessionWithSuccess(req:{ssid:string}){
        try{
            const resp  = await REQ_API.request({
                endpoint:"yapily/session/accept",
                method:"POST",
                data:{ssid:req.ssid}
            })
            if(resp.error)
                return resp.error

            return this.rebuildSessionObject(resp.data)
           
        }catch(e){
            return {code:-2,message:"Can't finalize session"}    
        }
    }

    rebuildSessionObject(data:any){
        const s={
            ssid:data.ssid,
            status:data.status,
            transaction_id:data.orderID,
            fail_reason:data.failReason,
            payment_system:data.paymentSystem,
            currency:data.currency,
            amount:data.amount,
            
            ctime:data.date,

            log:JSON.stringify(data.sessionLog),
            trx:JSON.stringify(data.transaction),
            card_mask:data.cardMask,
            ip:data.ip,
            country:data.country,
            branch:data.branch,
            email:data.email,
            ap_num:data.ap_num,
            gp_hash:data.gp_hash,
            dev_id:data.dev_id,
            merchant_id:data.merchant_id,
            terminal:data.terminal
        }
        return s
    }


    async getBankGroups(){
        try{
            const resp  = await REQ_API.request({
                endpoint:"yapily/banks/groups",
                method:"GET",
                data:{}
            })
            if(resp.error)
                return resp.error
            return resp.data
        }catch(e){
            return {code:-2,message:"Can't load terminals"}    
        }
    }
    async getBankList(){
        try{
            const resp  = await REQ_API.request({
                endpoint:"yapily/banks/list",
                method:"GET",
                data:{}
            })
            if(resp.error)
                return resp.error
            return resp.data
        }catch(e){
            return {code:-2,message:"Can't load terminals"}    
        }
    }

    async subappCreate(){
        console.log("YAPILY SUB APP CREATE!")
        try{
            const resp  = await REQ_API.request({
                endpoint:"yapily/subapp/create",
                method:"POST",
                data:{
                    name: "Impaya Assets OU",
                    merchantCategoryCode: 6531,
                    ppcUserGroup: "FINANCE",
                    callbackUrls: [
                        "https://openbanking.paysolo.net/api/auth/redirect"
                    ],
                    isContractPresent: true
                },
            })
            if(resp.error)
                return resp.error
            return resp.data
        }catch(e){
            return {code:-2,message:"Can't load terminals"}    
        }
    }
}