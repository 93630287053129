import { useTheme } from "styled-components";
import { AbsolutePanel } from "../components/AbsolutePanel"
import { Box } from "../components/box/Box";
import { useState } from "react";
import { Spacer } from "../components/Spacer";
import { Button } from "../components/Button";
import { Typo } from "../components/text/Typo";
import { StatBlock } from "./StatPanel";
import { S_YAPILY_BANK } from "./YapilyPanel";

export const YapilyBankPanel = () => {
    const theme = useTheme()
    const yapilyBank = S_YAPILY_BANK.use([])

    const [menu,setMenu] = useState<"details"|"raw"|"additional">("details")
    

    if(!yapilyBank)
        return null;
    
    let content=null;
    switch(menu){
        case "raw":
            content=<Box sx={{
                backgroundColor:"rgba(0,0,0,.3)",
                fontFamily:"monospace",
                whiteSpace:"pre-wrap",
                color:"greenyellow",
                fontSize:".9em",
                padding:theme.value(5),
            }}>
                {JSON.stringify(yapilyBank,null,"\t")}
            </Box>
        break
        case "details":
            content=<Box vflex>



                <Typo block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Bank info:</Typo>
                <Spacer size="10px" />
                <ClientInfo bank={yapilyBank} />
                

                <Spacer size="40px" />

                <Typo block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Media:</Typo>
                <Spacer size="10px" />
                <Media bank={yapilyBank} />

                <Spacer size="40px" />

                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Environment info:</Typo>
                <Spacer size="10px" />
                <CommonInfo bank={yapilyBank} />

                <Spacer size="40px" />

                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Countries:</Typo>
                <Spacer size="10px" />
                <Countries bank={yapilyBank} />


                <Spacer size="40px" />

                <Typo  block sx={{
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.3)",
                    padding:theme.value(5)
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:theme.value(3),
                        
                    }
                }}>Features:</Typo>
                <Spacer size="10px" />
                <Features bank={yapilyBank} />
                
                
            </Box>
        break;
        case "additional":
            content=<div>additional</div>
        break;
    }

    return <AbsolutePanel title="Yapily Institution" sx={{
        width:"50vw",
        height:"100%",
        maxWidth:"650px"
    
    }}>
        <Box vflex sx={{
            height:"100%",
            gap:"10px"
            ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                fontSize:"0.8em"
            }
        }}>
            <Box sx={{
                display:"flex",
                gap:theme.value(5)
                ,"&>div":{
                    textTransform:"uppercase",
                    padding:theme.value(2),
                    borderBottom:"2px solid rgba(0,0,0,.2)",
                    cursor:"pointer"
                    ,"&[data-selected='true']":{
                        color:"#33a0ff",
                        borderBottom:"2px solid #33a0ff",
                        pointerEvents:"none",
                    }
                }
            }}>
                <div onClick={()=>setMenu("details")} data-selected={menu==="details"}>Details</div>
                <div onClick={()=>setMenu("raw")}  data-selected={menu==="raw"}>RAW</div>
                <div onClick={()=>setMenu("additional")}  data-selected={menu==="additional"}>ADDITIONAL</div>
            </Box>

            <Box sx={{
                flexGrow:1,
                position:"relative",
                overflow:"auto",
                "&>div":{
                    position:"absolute",
                    width:"100%"
                }
            }}><div>
                {content}
            </div></Box>
            
            <Box sx={{display:"flex"}}>
                <Spacer grow />
                <Button size="small" onClick={()=>{S_YAPILY_BANK.invoke(null)}}>CLOSE</Button>
            </Box>

        </Box>
    </AbsolutePanel>
}


const CommonInfo=({bank}:{bank:IYapilyInstitution})=>{
    const theme = useTheme()
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat( auto-fit, minmax(250px,1fr) )",
        gap:theme.value(3)
    }}>

    <StatBlock name={"Credential type"} value={bank.credentialsType} small/>
    <StatBlock name={"Environment"} value={bank.environmentType} small/>
    
    </Box>
}



const ClientInfo=({bank}:{bank:IYapilyInstitution})=>{
    const theme = useTheme()
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat( auto-fit, minmax(250px,1fr) )",
        gap:theme.value(3)
    }}>
        <StatBlock name={"Full name"} value={bank.fullName} small/>
        <StatBlock name={"Name"} value={bank.fullName} small/>
        <StatBlock name={"Id"} value={bank.id ?? "??.??.??.??"} small/>
      
    </Box>
}

const Media = ({bank}:{bank:IYapilyInstitution}) =>{
    const theme = useTheme()
    return <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat( auto-fit, minmax(200px,1fr) )",
        gap:theme.value(3)
    }}>
        {bank.media.map((val,i)=>{
            return <Box key={i}vflex sx={{
                gap:theme.value(2),
                cursor:"pointer",
                justifyContent:"center",
                padding:theme.value(2),
            }}
            
            onClick={()=>{
                window.open(val.source,"_blank")
            }}

            >
                <Box
                sx={{
                    width:"100%",
                    paddingTop:"100%",
                    backgroundSize:"contain",
                    backgroundPosition:"center",
                    backgroundRepeat:"no-repeat",
                    borderRadius:"6px",
                    backgroundColor:"rgba(0,0,0,.4)",//"rgba(236, 233, 231, 0.825)",
                    boxShadow:"0 0 5px rgba(0,0,0,.5)"
                   
                }}
                style={{
                    backgroundImage:`url(${val.source})`,
                }}></Box>
                <Typo block uppercase>{val.type}</Typo>
                <Typo block variant="small">{val.source}</Typo>
            </Box>
        })}
        
    </Box>
}

const Countries = ({bank}:{bank:IYapilyInstitution}) =>{
    const theme = useTheme()
    return <Box vflex sx={{
        gap:theme.value(1),
        
    }}> {bank.countries.map((val,i)=>{
        return <Box sx={{
            backgroundColor:"rgba(0,0,0,.1)",
            display:"flex",
            padding:`${theme.value(3)} ${theme.value(5)}`,
            gap:theme.value(4),
            alignItems:"center",
        }} key={i}>
              
               
                <Box sx={{
                    width:"20px",height:"20px",backgroundSize:"cover",backgroundColor:"red",boxShadow:"0 0 5px rgba(0,0,0,.5)",borderRadius:"50%",backgroundPosition:"center",backgroundRepeat:"no-repeat"
                }} style={{backgroundImage:`url(https://flagcdn.com/${val.countryCode2.toLowerCase()}.svg)`}}></Box>

            
            <Typo sx={{
                "&:first-letter":{
                    textTransform:"capitalize"
                }
            }}>
               {val.countryCode2} - {val.displayName}
            </Typo>
        </Box>
    })}</Box>
}

const featureColor:Record<string,string> = {
    "CREATE_DOMESTIC_SINGLE_PAYMENT":"#00FFAA"

}

const Features = ({bank}:{bank:IYapilyInstitution}) =>{
    const theme = useTheme()
    return <Box vflex sx={{
        gap:theme.value(1),
        
    }}> {bank.features.map((val,i)=>{
        return <Box sx={{
            backgroundColor:"rgba(0,0,0,.1)",
            display:"flex",
            padding:`${theme.value(3)} ${theme.value(5)}`,
            gap:theme.value(3),
            alignItems:"center",
            color:featureColor[val] ?? undefined
        }} key={i}>
            <Typo variant="small">
                {(i+1).toString().padStart(2,"0")}
            </Typo> 
            <Typo sx={{
                "&:first-letter":{
                    textTransform:"capitalize"
                }
            }}>
               {val} 
            </Typo>
        </Box>
    })}</Box>
}

