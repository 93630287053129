import { Box } from "@mui/material";
import { IBlock, REQ_BLOCKS, REQ_EDIT_BLOCK, S_BLOCK_LOAD_ERROR, S_BLOCK_REQUEST_EDIT, S_BLOCK_SAVE_ERROR } from "../managers/Blocks";
import { useEffect, useState } from "react";
import { TextInput } from "../components/input/TextInput";

export const BlocksPanel = () =>{
    
    const error = S_BLOCK_LOAD_ERROR.use([])

    const [blocks,retakeBlocks,,busy]=REQ_BLOCKS.useRequest()

    const handleEditBlock = (block?:IBlock) =>{
        S_BLOCK_REQUEST_EDIT.invoke(block)
    }



    return <div>
        {busy && <div>loading</div>}
        <div onClick={()=>{if(busy)return;retakeBlocks(+new Date())}}>REFRESH</div>
        <div onClick={()=>{handleEditBlock()}}>ADD BLOCK</div>

        {blocks && blocks.map((val,i)=>{
            return <Box sx={{
                display:"grid",
                gridTemplateColumns:"max-content max-content max-content 1fr 1fr",
                "&>div":{
                    wordBreak:"break-word",
                    borderBottom:"1px solid red",
                    padding:"10px"
                },

            }}
            onClick={()=>{
                handleEditBlock(val)
            }}
            key={i}>
                <div>{val.block_uid}</div>
                <div>{val.created_at.getDate()}</div>
                <div>{val.created_by}</div>
                <Box sx={{whiteSpace:"pre-wrap"}}>{val.reason}</Box>
                <Box sx={{whiteSpace:"pre-wrap"}}>{val.status}</Box>
                </Box>
        })}
    </div>
}