import { Box } from "./box/Box";
import { Typo } from "./text/Typo";

export const Price=({currency,amount}:{currency:string,amount:number})=>{

    const tmp = (amount/100).toFixed(2);

    return <Box sx={{
        display:"flex",
        gap:"10px",
        alignItems:"baseline"
    }}>
        <Typo variant="caption">{tmp}</Typo>
        <Typo variant="caption" sx={{textTransform:"uppercase",color:"rgba(255,255,255,.5)"}}>{currency}</Typo>

    </Box>
}