import {ReactElement } from "react"
import { Divider } from "./Divider"
import styled, { keyframes, useTheme } from "styled-components"
import { Box } from "./box/Box"
import { Spacer } from "./Spacer"
import { isMobile } from "react-device-detect"

interface IAbsolutePanel{
    children?:any
    title?:string|ReactElement
    sx?:React.CSSProperties
    busy?:boolean
}

const animation = keyframes`
    0% {
      transform: translateY(40px);
      opacity: .5;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
`

const Anibox=styled.div`
    animation: ${animation} 0.2s ease-out;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    overflow: hidden;
`


export const AbsolutePanel = ({children,title,sx,busy}:IAbsolutePanel) =>{
    const theme = useTheme()

   

    return <Box sx={{
        position:"absolute"
        ,top:"0"
        ,left:"0"
        ,width:'100vw'
        ,height:"100vh"
        ,display:"flex"
        ,flexDirection:"column"
        ,alignItems:"center"
        ,justifyContent:"center"
        ,zIndex:"10"
        ,backgroundColor:"rgba(0,0,0,.4)",
        overflow: "hidden"

    }}>
        <Anibox><Box sx={{
           
           ...theme.panelSize,

            
            
            backgroundColor:theme.colors.panelBgColor,
            borderRadius:"4px",
            boxShadow:"0px 0px 20px rgba(0,0,0,.8)",
            display:"flex",
            flexDirection:"column",
            border:"1px solid rgba(255,255,255,.05)",
              ...sx
        }}>
            {title && <Box sx={{
                backgroundColor:"rgba(0,0,0,.1)",
            }}>
                <Box sx={{
                    padding:`${theme.value(5)} ${theme.value(10)}`
                    ,fontSize:"1.5em"
                    ,fontWeight:"300"
                    ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                        padding:`${theme.value(5)} ${theme.value(7)}`,
                        fontSize:"1.1em"
                    }
                }}>
                    {title}
                </Box>
            </Box>}
            <Divider />
            <Box sx={{
                padding:theme.value(10),
                fontSize:"1em"
                ,fontWeight:"400"
                ,flexGrow:"1"
                ,[`@media (max-width: ${theme.values.rebuildWidth})`]:{
                    padding:`${theme.value(7)} ${theme.value(7)}`,
                }
            }}>
                {children}
            </Box>
            
        </Box></Anibox>
    </Box>
}