import Signal from "badmfck-signal";
import { AbsolutePanel } from "../components/AbsolutePanel"
import { Typo } from "../components/text/Typo";
import { ISession, REQ_SESSION_UPDATE } from "../managers/Sessions";
import { Box } from "../components/box/Box";
import { Button } from "../components/Button";
import { Spacer } from "../components/Spacer";
import { useState } from "react";
import { REQ_YAPILY_FINALIZE_SESSION } from "../managers/Yapilly";
import { useTheme } from "styled-components";
import { StatBlock } from "./StatPanel";


export const S_YAPILY_FORCE_FINALIZE_DIALOG_REQUEST = new Signal<ISession|null>();

export const YapilyForceFinalizeSessionPanel = () =>{
    const theme = useTheme();
    const [busy,setBusy] = useState<boolean>(false);
    const [error,setError] = useState<{code:number,message:string}|null>(null);
    const session = S_YAPILY_FORCE_FINALIZE_DIALOG_REQUEST.use([]);

    if(!session)
        return null;

    const sendSessionFinalization = async ()=>{
        setError(null);
        setBusy(true)
        const response = await REQ_YAPILY_FINALIZE_SESSION.request({ssid:session.ssid})
        console.log(response)
        if("code" in response && "message" in response)
            setError(response)
        else{
            const s = await REQ_SESSION_UPDATE.request(response);
            S_YAPILY_FORCE_FINALIZE_DIALOG_REQUEST.invoke(s);
        }

        setBusy(false);
    }


    return <AbsolutePanel title="Change session status" busy={busy}>

            {error && <><Box sx={{
                border:"1px solid #ff0084",
                textAlign:"center",
                fontWeight:"500",
                fontSize:"0.85em",
                padding:theme.values.value16px,
                borderRadius:theme.values.borderRadius,
                backgroundColor:"rgba(0,0,0,.1)"
            }}>
                {error.message}
            </Box><Spacer size="20px"/></>}

        <Box vflex sx={{flexGrow:1}}>
            <Box>
                <Typo variant={"head"} weight="500" block> Are you sure you want to<br/>finalize session with <b><i>"completed"</i></b> status?</Typo>
                <Spacer size="10px"/>
                <Typo sx={{opacity:1}} block color="#ffbc13"> Your activity will be reported.</Typo>
            </Box>
            <Spacer size="20px"/>

           
            <Box sx={{
                display:"grid",
                gridTemplateColumns:"1fr 1fr",
                gap:theme.value(3)
            }}>
                <StatBlock name={"TRANSACTION ID"} value={session.transaction_id} small/>
                <StatBlock name={"CLIENT"} value={session.email} small/>

                <StatBlock name={"AMOUNT"} value={(session.amount/100).toFixed(2)} small/>
                <StatBlock name={"CURRENCY"} value={session.currency.toUpperCase()} small/>

                <StatBlock name={"CURRENT"} bold value={session.status.toUpperCase()}/>
                <StatBlock name={"CHANGE TO"} danger bold value={"COMPLETED"}/>

            </Box>
            

            <Spacer size="40px"/>
            <Box sx={{display:"flex",gap:"10px"}}>
                <Spacer grow />
                {(session.paymentSystem?.toLowerCase()==="yapily" || session.paymentSystem?.toLowerCase()==="yapily_instant") && <Button size="small" variant="danger" sx={{backgroundColor:"red"}} disabled={busy} onClick={sendSessionFinalization}>{busy?"PROCESSING...":"CHANGE"}</Button>}
                <Button size="small" disabled={busy} onClick={()=>{ setError(null);S_YAPILY_FORCE_FINALIZE_DIALOG_REQUEST.invoke(null)}}>CANCEL</Button>
            </Box>
        </Box>
    </AbsolutePanel>

}