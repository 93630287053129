import { Req } from "badmfck-signal"
import { IError, REQ_API } from "./Api"

export interface IStatRequest {
    date:string, // utc
    dateFilterType:"byday"|"bymonth"
}

export interface IStat{
    data:{
        byBranch:{
            data:{count:number,percent:number,label:string}[],
            error?:string|null
        },
        byCountry:{
            data:{count:number,percent:number,label:string}[],
            error?:string|null
        },
        byPaymentSystem:{
            data:{count:number,percent:number,label:string}[],
            error?:string|null
        },
        byStatus:{
            data:{count:number,percent:number,label:string}[],
            error?:string|null
        },
        byTerminal:{
            data:{count:number,percent:number,label:string}[],
            error?:string|null
        },
        statusByPaymentSystem:{
            data:{
                count:number,
                status:string,
                payment_system:string
                label?:string
            }[],
            error?:string|null
        }[]
            
    },
    updated:number,
    table:string
}

const ps:Record<string,string>={
    ap:"Apple Pay",
    gp:"Google Pay",
    yapily:"OB/Yapily",
    yapily_instant:"OB/Yapily Instant",
    yapily_repay:"OB/Yapily Repay"
}

export const REQ_STAT = new Req<IStatRequest,IStat|IError>(async req=>{

    console.log("REQ_STAT",req)
    if(!req){
        console.error("Can't get stat, no filter object")
        return {code:-1,message:"No request data"}
    }

    const resp = await REQ_API.request({
        endpoint:"stat",
        data:req
    })

    if(resp.error)
        return resp.error


    // normalize
    const data:IStat = resp.data;

    if(!data)
        return {code:2,message:"No data"}
    
    normalizeStat(data.data.byCountry.data);
    normalizeStat(data.data.byBranch.data);
    normalizeStat(data.data.byPaymentSystem.data);
    normalizeStat(data.data.byStatus.data);
    normalizeStat(data.data.byTerminal.data);

    for(let i of data.data.statusByPaymentSystem){
        
        if(!i.data)
            continue;
        
        let total = 0;
        let processed=0;
        let completed=0;

        for(let j of i.data){
            total+=j.count;
            if(j.status!=="abandoned" && j.status!=="created" && j.status!=="expired" && j.status!=="processing")
                processed+=j.count;
            if(j.status==="completed")
                completed+=j.count;
            const label = ps[j.payment_system] ?? j.payment_system;
            j.label = label;
        }
        
        // sort first processed,then completedm then conversion, then all
      

        
        
        i.data.push({count:total,status:"total",payment_system:i.data[0].payment_system})
        i.data.push({count:processed,status:"processed",payment_system:i.data[0].payment_system})
        i.data.push({count:Math.round((processed===0?0:completed/processed)*100),status:"conversion",payment_system:i.data[0].payment_system})


        i.data.sort((a,b)=>{
            if(a.status==="processed")
                return -1;
            if(b.status==="processed")
                return 1;
            if(a.status==="completed")
                return -1;
            if(b.status==="completed")
                return 1;
            if(a.status==="conversion")
                return -1;
            if(b.status==="conversion")
                return 1;
            return 0;
        })

    }

    return data;
})

const normalizeStat = (data:any[])=>{
    if(!data || !data.length)
        return;
    // find/minmax
    let min = data[0].count;
    let max = data[0].count;
    data.forEach(val=>{
        if(val.count>max)
            max = val.count;
        if(val.count<min)
            min = val.count;
    })

    // create percentage value for each
    data.forEach(val=>{
        val.percent = (val.count*100)/max
    })

    // sort from big to small
    data.sort((a,b)=>b.count-a.count);
}

