import { styled, useTheme } from "styled-components"
import { Box } from "./box/Box"

export interface IButtonProps{
    children?:any
    element?:'div'|"a"|"input"
    onClick?:(e?:MouseEvent)=>void
    variant?:"outline"|"filled"|"empty"|"danger"
    value?:string,
    sx?:React.CSSProperties|any
    size?:"small",
    disabled?:boolean
}


export const Button = ({children,disabled,onClick,variant,element,value,sx,size}:IButtonProps) =>{
    if(!variant)
        variant="filled"
    const theme = useTheme()

    let overrideInput:React.CSSProperties|any={}
    if(element==="input"){
        overrideInput={
            backgroundColor: "transparent",
            color:theme.colors.textPrimary,
            whiteSpace: "normal",
            paddingBlock: 0,
            paddingInline: 0,
            border:"none"
        }
        if(variant==="filled"){
            overrideInput={
                ...overrideInput,
                backgroundColor:theme.colors.buttonBgColor,
                padding:theme.input.padding,
                transition:"background-color .2s",
                "&:hover":{
                    backgroundColor:"red"
                }
            }
        }

    }

    let fontSize="1em"
    if(size==="small")
        fontSize="0.82em"
   
    return <Box 
        element={element}
        type={element==="input"?"submit":undefined}
        data-variant={variant}
        value={value} onClick={(e)=>{if(!disabled && onClick)onClick(e)}} sx={{
   
        borderRadius:theme.input.borderRadius,
        textAlign:"center",
        cursor:"pointer",
        boxShadow:theme.input.shadow,
        position:"relative",
        display:"flex",
        overflow:"hidden",
        pointerEvents:disabled?"none":undefined,

        "&[data-variant='filled']":{
            backgroundColor:disabled?theme.colors.buttonDisabledBgColor:theme.colors.buttonBgColor,
        },
        
        "&[data-variant='danger']":{
            backgroundColor:"#e91e63",
        },

        "&[data-variant='outline']":{
            border:`1px solid ${theme.colors.buttonOutlineColor}`,
        },
        ...overrideInput,
        ...sx
    }}>
        <Box sx={{
            position:"relative",
            padding:size==="small"?theme.input.paddingSmall:theme.input.padding,
            flexGrow:"1",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            transition:"background-color .2s",
            "&:hover":{
                backgroundColor:"rgba(0,0,0,.2)",
            }
        }}>
            <Box sx={{
                userSelect:"none",
                pointerEvents:"none",
                position:"relative",
                transform:"translate(0px,1px)",
                fontSize:fontSize
            }}>
                {children && children}
                {!children && value && value}
            </Box>
        </Box>
    </Box>
}