import { Req } from "badmfck-signal";
import { IProfile, S_AUTH_COMPLETE, S_AUTH_ERROR, S_AUTH_ERROR_ON_CALL } from "./Auth";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Config } from "../Config";
import { request } from "http";



interface IAPIRequest{
    ignoreAuth?:boolean,
    data?:any,
    endpoint:string,
    method?:"POST"|"GET"|"PUT"|"DELETE"
}

export interface IError{
    code:number,
    message:string
}

interface IAPIResponse{
    error:IError
    data:any
}


// Monitoring user
let user:IProfile|null=null;
export class Api{
    constructor(){
        S_AUTH_COMPLETE.subscribe(u=>user = u)
        S_AUTH_ERROR.subscribe(err=>user=null)
    }
}
//S_AUTH_COMPLETE.subscribe(u=>user = u)




export const REQ_API=new Req<IAPIRequest,IAPIResponse>();
REQ_API.listener=async req=>{

    let h ={};
    if(user && !req.ignoreAuth)
        h={"Authorization":user?.token}

    let response=null;
    let u = Config.scheme.URL_API+req.endpoint

  

    try{

        const body: AxiosRequestConfig = {
            url: u,
            method: req.method ?? "POST",
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...h
            },
            data: req.data,
        };

        response = await axios(body);
    }catch(e){

        const err = e as AxiosError;
        if(err.response?.status == 401){
            let callback:any;
            const promise = new Promise((resolve,reject)=>{
                callback=resolve;
            })
            S_AUTH_ERROR_ON_CALL.invoke({code:-1,message:"unauthorized request",resolve:{
                request:req,
                callback:callback
            }})
            return promise;
        }
        
        if(err.response && err.response.data && (err.response.data as any).error)
            return err.response.data
        return {code:-2,message:"can't authenticate, check your network & credentials"}
    }
    if(!response || !response.data )
        return {code:-3,message:"empty response"}
    return response.data;
}