import { useTheme } from "styled-components"
import { Box } from "../box/Box"

export interface ITypoProps{
    block?:boolean
    children?:any
    variant?:any,
    sx?:React.CSSProperties|any
    capitalize?:boolean
    uppercase?:boolean,
    align?:string,
    weight?:string
    color?:string
    onClick?:(e?:any)=>void
}
export const Typo = ({onClick,children,variant,sx,block,capitalize,uppercase,align,weight,color}:ITypoProps) =>{
    const theme = useTheme()
    let tsx=sx
    if(!tsx)
        tsx={}
    
    if(!tsx.display && !block)
        tsx.display="inline"
    if(!tsx.overflow){
        tsx.textOverflow="ellipsis"
        tsx.overflow="hidden"
    }

    if(!tsx.textAlign && align)
        tsx.textAlign = align as any

    if(!tsx.fontWeight && weight)
        tsx.fontWeight = weight as any

    if(!tsx.color && color)
        tsx.color = color as any

    if(!tsx.textTransform && capitalize)
        tsx.textTransform="capitalize"

    if(!tsx.textTransform && uppercase)
        tsx.textTransform="uppercase"

    if(variant){
        const css=theme.typo[variant]
        for(let i in css){
            const val = css[i]
            if((val+"").startsWith("--")){
                const tval=(val+"").substring(2)
                if(tval in theme.colors)
                    css[i] = theme.colors[tval];
            }

        }
        if(css)
            tsx={...css,...tsx}

        
    }

    return <Box onClick={onClick} sx={tsx}>{children}</Box>
}