import { AbsolutePanel } from "../components/AbsolutePanel"
import { S_YAPILY_BANK_GROUP } from "../managers/Yapilly"

export const YapilyBankGroupPanel = () => {
    const bankGroup = S_YAPILY_BANK_GROUP.use([])
    if(!bankGroup)
        return null;
    return  <AbsolutePanel title="Yapily Bank Group" sx={{
        width:"50vw",
        height:"100%",
        maxWidth:"650px"
    }}>
      BANK GROUPS
    </AbsolutePanel>
}