import { Req } from "badmfck-signal";

export interface IScheme{
   URL_API:string
   name:"newtech"|"local"|"paysolo"|"paypartner"|"openbanking",
   title:string
   selected:boolean
}


const schemes:IScheme[] = [
   {URL_API:"https://apgp.newtech.lv/api/skynet/",selected:true,name:"newtech",title:"Newtech.lv"},
   {URL_API:"https://apgp.paysolo.net/api/skynet/",selected:false,name:"paysolo",title:"Paysolo.net"},
   {URL_API:"https://apgp.paypartner.eu/api/skynet/",selected:false,name:"paypartner",title:"Paypartner.eu"},
   {URL_API:"http://localhost:8091/api/skynet/",selected:false,name:"local",title:"Localhost:8091"},
   {URL_API:"https://openbanking.paysolo.net/api/skynet/",selected:false,name:"openbanking",title:"Openbanking"}
]


export const REQ_HOST=new Req<string,IScheme[]>();
export class Config{

   constructor (){
      this.init();
   }
   
   async init(){
      REQ_HOST.listener=async newhost=>await this.setupHost(newhost)
   }

   async setupHost(host:string):Promise<IScheme[]>{
      console.log(host)
      if(host){
         schemes.forEach(val=>val.selected=host===val.URL_API)
         Config.scheme=schemes.find(val=>val.selected)!
      }
      return [...schemes];
   }

   static scheme:IScheme = schemes.find(val=>val.selected)!;
	static version:string="1.6.8"
}

new Config();