import Signal, { Req } from "badmfck-signal";
import { IError, REQ_API } from "./Api";

interface IBlockForm{
    block_uid?:string|null
	email?:string|null
	country?:string|null
	ip?:string[]|null
	reason?:string|null
    ap_num?:string|null
    gp_hash?:string|null
    dev_id?:string|null
    status?:"active"|"disabled"|"deleted"
}

export interface IBlockFormSaveRequest{
    form?:IBlockForm,
    saved?:boolean,
    deleteUID?:string,
    deleted?:boolean,
}

export interface IBlock{
    block_uid:string
	created_at:Date
	created_by:string
	email:string
	country:string
	card_mask:string
	ip:string[]
	reason:string
    ap_num:string
    gp_hash:string
    dev_id:string
    status:"active"|"disabled"|"deleted"
}

export const REQ_BLOCKS=new Req<number,IBlock[]>();
export const REQ_EDIT_BLOCK=new Req<IBlockFormSaveRequest|null,IBlockFormSaveRequest|null>();
export const S_BLOCK_LOAD_ERROR = new Signal<IError|null>();

export const S_BLOCK_SAVE_ERROR = new Signal<IError|null>();
export const S_BLOCK_DELETE_ERROR = new Signal<IError|null>();
export const S_BLOCK_REQUEST_EDIT = new Signal<IBlock|null|undefined>();
export const S_BLOCK_EDIT = new Signal<IBlockForm|null>();

export class Blocks{
    
    constructor(){
        REQ_BLOCKS.listener = async num=>this.requestBlocks(num)
        REQ_EDIT_BLOCK.listener = async req=>this.requestEditBlocks(req);
        S_BLOCK_REQUEST_EDIT.subscribe(block=>this.onBlockRequestEdit(block))
    }

    onBlockRequestEdit(block:IBlock|null|undefined){
         // Format edit block and return edit form
         let editBlock:IBlockForm = {
            block_uid:null
            ,email:null
            ,country:null
            ,ip:[]
            ,reason:null
            ,ap_num:null
            ,gp_hash:null
            ,dev_id:null
            ,status:"active"
        }

        if(block){
            editBlock={
                block_uid:block.block_uid
                ,email:block.email
                ,country:block.country
                ,ip:block.ip
                ,reason:block.reason
                ,ap_num:block.ap_num
                ,gp_hash:block.gp_hash
                ,dev_id:block.dev_id
                ,status:block.status
            }
        }
        S_BLOCK_EDIT.invoke(JSON.parse(JSON.stringify(editBlock)) as IBlockForm)
    }

    async requestEditBlocks(req?:IBlockFormSaveRequest|null){

        if(!req)
            return null;

        // Save, return result
        if(req.form){
            const savedBlock = await this.sendBlockForm(req.form)
            return {saved:savedBlock!==null}
        }

        // Delete block and return empty form (close editor)
        if(req.deleteUID){
            const success = await this.sendDelete(req.deleteUID)
            return {deleted:success}
        }

        return null;
    }


    async sendDelete(uid:string){
        S_BLOCK_DELETE_ERROR.invoke(null)
        try{
           const resp  = await REQ_API.request({
               endpoint:"blocks",
               data:{uid:uid},
               method:"DELETE"
           })
           if(resp.error){
               S_BLOCK_SAVE_ERROR.invoke(resp.error)
               return false
           }
               
           return true

       }catch(e){
           S_BLOCK_LOAD_ERROR.invoke({code:-2,message:"Can't load terminals"})  
           return false
       }
    }


    async sendBlockForm(form:IBlockForm){
         // save blocks
         S_BLOCK_SAVE_ERROR.invoke(null)
         try{
            const resp  = await REQ_API.request({
                endpoint:"blocks",
                data:form,
                method:"POST"
            })
            
            if(resp.error){
                S_BLOCK_SAVE_ERROR.invoke(resp.error)
                return null
            }
                
            return this.normalizeBlock(resp.data);

        }catch(e){
            S_BLOCK_LOAD_ERROR.invoke({code:-2,message:"Can't load terminals"})  
            return null
        }
    }

    async requestBlocks(num:number):Promise<IBlock[]>{
        // get blocks
        try{
            const resp  = await REQ_API.request({
                endpoint:"blocks",
                data:{},
                method:"GET"
            })
            if(resp.error){
                S_BLOCK_LOAD_ERROR.invoke(resp.error)
                return []
            }
            const blocks:IBlock[]=[]
            for(let i of resp.data)
                blocks.push(this.normalizeBlock(i))

            return blocks
        }catch(e){
            S_BLOCK_LOAD_ERROR.invoke({code:-2,message:"Can't load blocks"})  
            return []
        }
        
    }
    normalizeBlock(i:any):IBlock{
        let ips = i.ip ?? "";
        ips = ips.replaceAll(/[\s\t\n\r]/g,"");
        ips = ips.replaceAll(/[;]/g,",");
        i.ip = ips;
        i.country = i.country?.toLowerCase() ?? null
        i.email = i.email?.toLowerCase() ?? null
        i.created_at = new Date(i.created_at)
        return i;
    }
}

    