import { Req } from "badmfck-signal";
import { IError, REQ_API } from "./Api";

export interface ITerminal{
    name:string,
    system:string
}

export const REQ_TERMINALS = new Req<void,ITerminal[]|IError>()

export class Terminals{
    constructor(){
        REQ_TERMINALS.listener = async ()=>await this.getTerminals()
    }

    async getTerminals(){
        try{
            const resp  = await REQ_API.request({
                endpoint:"terminals",
                data:{},
            })
            if(resp.error)
                return resp.error
            return resp.data
        }catch(e){
            return {code:-2,message:"Can't load terminals"}    
        }
    }
}
